import React from 'react';
import classes from './confirmationPrompt.module.css';
import PromptSign from '../../assets/images/promptWarning.png'

interface Props {
  visible?: boolean | undefined;
}
const ConfirmationPrompt = ({
  visible,
}: Props) => {

  return (
    <>
      {visible ? (
        <div className={classes.mainContainer}>
            <img src={PromptSign} alt=''></img>
            <h3>Don&apos;t forget to confirm your changes</h3>
          </div>
      ) : null}
    </>
  );
};
export default ConfirmationPrompt;
