import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import * as api from '../api';

export interface StampsInterface {
  apiAuthSuccess: boolean;
  hasFetched: boolean;
  memberId: string;
  stampsBalance: number | null;
  previousStampsBalance: number;
  spendToNextStamp: number;
  stampThreshold: number;
}

const initialState: StampsInterface = {
  apiAuthSuccess: false,
  hasFetched: false,
  memberId: '',
  stampsBalance: null,
  previousStampsBalance: 0,
  spendToNextStamp: 0,
  stampThreshold: 0
};

const stampsSlice = createSlice({
  name: 'stampsSlice',
  initialState,
  reducers: {
    setStampsData(state, action: PayloadAction<StampsInterface>) {
      return { ...action.payload, apiAuthSuccess: true, hasFetched: true };
    },
    setApiAuthSuccess(state) {
      state.apiAuthSuccess = true;
    }
  }
});

export const fetchStampsData = (): AppThunk => async (dispatch, getState) => {
  const apiKey = 'fetchStampsData';

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const stampsData = await api.fetchStampsData(token as string);
    dispatch(stampsSlice.actions.setStampsData(stampsData));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    if (status !== 401) {
      dispatch(stampsSlice.actions.setApiAuthSuccess());
    }

    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default stampsSlice;
