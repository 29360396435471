import { ERRORS } from './../utils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ApiKeyTypes =
  | 'fetchStampsData'
  | 'fetchTransactions'
  | 'fetchMemberBenefits'
  | 'fetchOffers'
  | 'fetchCommunityOffers'
  | 'fetchCharityOffers'
  | 'fetchRewards'
  | 'fetchVoucher'
  | 'fetchMemberSavings'
  | 'fetchMemberDetails'
  | 'fetchUserPreferences'
  | 'fetchUnsubscribePreferences'
  | 'fetchProgressionPoint'
  | 'updateUserPreferences'
  | 'fetchMemberGUID'
  | 'fetchBulkData';

interface ApiErrorInterface {
  status: any;
  message: string;
  apiKey: ApiKeyTypes;
  type?: 'post' | 'get';
}

export interface BlockingApiErrorInterface {
  status: number;
  heading: string;
  message: string;
  message2?: string;
  apiKey?: string;
}

export interface InlineApiErrorInterface {
  status: number;
  message: string;
  apiKey: ApiKeyTypes;
  type?: 'post' | 'get';
}

export type ViewTypes = 'mobile' | 'tablet' | 'desktop' | null;

export interface UiInterface {
  blockingApiError: BlockingApiErrorInterface | null;
  inlineApiErrors: ApiErrorInterface[];
  loadingApis: ApiKeyTypes[];
  view: ViewTypes;
  isStampsRewards: boolean;
}

const initialState: UiInterface = {
  blockingApiError: null,
  inlineApiErrors: [],
  loadingApis: [],
  view: null,
  isStampsRewards: false
};

const uiSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setApiError(state, action: PayloadAction<ApiErrorInterface>) {
      const { status, apiKey, message, type } = action.payload;

      if (status === 401) {
        state.blockingApiError = {
          status,
          heading: ERRORS.UNAUTHORIZED.HEADING,
          message: ERRORS.UNAUTHORIZED.MOBILE_MSG1,
          message2: ERRORS.UNAUTHORIZED.MSG2,
          apiKey
        };
      } else if (status === 404) {
        state.blockingApiError = {
          status,
          heading: ERRORS.UNAUTHORIZED.HEADING,
          message: ERRORS.UNAUTHORIZED.MOBILE_MSG1,
          message2: ERRORS.UNAUTHORIZED.MSG2,
          apiKey
        };
      } else {
        state.inlineApiErrors.push({
          status,
          apiKey,
          message,
          type
        });
      }
    },
    clearApiError(state, action: PayloadAction<string>) {
      state.inlineApiErrors = state.inlineApiErrors.filter(
        ({ apiKey }) => apiKey !== action.payload
      );
    },
    addApiLoading(state, action: PayloadAction<ApiKeyTypes>) {
      const apiKey = action.payload;
      const alreadyLoading = state.loadingApis.some(value => value === apiKey);

      if (!alreadyLoading) {
        state.loadingApis.push(apiKey);
      }
    },
    removeApiLoading(state, action: PayloadAction<ApiKeyTypes>) {
      const apiKey = action.payload;
      state.loadingApis = state.loadingApis.filter(value => value !== apiKey);
    },
    setView(state, action: PayloadAction<ViewTypes>) {
      state.view = action.payload;
    }
  }
});

export default uiSlice;
