import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';

import classes from './Unsubscibe.module.css';
import InterestCheckbox from '../../components/InterestCheckbox/InterestCheckbox';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  OptionItemInterface,
  SectionInterface,
  SettingsInterface,
  fetchUnsubscribePreferences,
  updateUnsubscibeSettings
} from '../../appState/unsubscribeSlice';

import { RootState } from '../../appState/rootReducer';
import { editmail } from '../../assets/images';
import { AuthInterface } from '../../appState/authSlice';
import ConfirmBox from '../../components/ConfirmationBox/ConfirmationBox';
import DialogSuccessBox from '../../components/DialogBox/DialogSuccessBox';
import { fetchPublicCustomerPref, fetchUnsubscibeSettings, setSubscription, unSubscribePreferences, updatePublicCustomerPref } from '../../api';
import ConfirmationPrompt from '../../components/DialogBox/ConfirmationPrompt';
import { fetchUserPreferences } from '../../appState/userSettingsSlice';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';
import { useLocation } from 'react-router-dom';

interface UnsubscribeContainerProps {
  code: any;
  customerId: any;
  pathName: any;
}
interface ToastConfig {
  visible: boolean;
  message: string;
  type: 'ERROR' | 'SUCCESS';
}

const UnsubscribeContainer: React.FC<UnsubscribeContainerProps> = props => {
  const dispatch = useDispatch();

  const { sections: settingsData = [] } = useSelector<
    RootState,
    SettingsInterface
  >(({ unsubscibeSlice }) => unsubscibeSlice);

  const { token, memberId } = useSelector<RootState, AuthInterface>(
    ({ authSlice }) => authSlice
  );

  const [settingsState, setSettingsState] = useState<Array<SectionInterface>>(
    settingsData
  );



  // const section3 = settingsData.find(section => section.label === 'section3');

  const [toggle, setToggle] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [unsubConfirmation, setUnsubConfirmation] = useState(false);
  const [custId, setCustId] = useState<string | null>('');
  const [options, setOptions] = useState<Array<OptionItemInterface>>([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [hashCustId, setHashCustId] = useState<string | null>('');
  const [sections, setSections] = useState<Array<SectionInterface>>([]); 
  const [settingSections, setSettingSections] = useState<SettingsInterface>({
    sections: [],
    id: '',
    customerId: ''
  });

  const [section1, section2, section3] = sections;
  const [isLoading, setIsLoading] = useState<boolean>(false);


  const [toastConfig, setToastConfig] = useState<ToastConfig>({
    visible: false,
    message: '',
    type: 'ERROR'
  });
  const hideToast = () => {
    setToastConfig({
      visible: false,
      message: '',
      type: 'SUCCESS'
    });
  };

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState<boolean>(true);

  useEffect(() => {
    if (!initialRender) {
      const [, , section3Cached] = sections;
      const [, , section3Local] = settingSections?.sections;
  
      if (section3Cached && section3Local) { 
        const areEqual = section3Cached.options.every(
          (local, i) => local.selected === section3Local.options[i].selected
        );
        setShouldUpdate(!areEqual);
      }
    }
    setInitialRender(false);
  }, [initialRender, settingSections, sections]);

  useEffect(() => {
    setSettingsState(settingsData);
  }, [settingsData]);

  useEffect(() => {
    if (section3?.options) {
      setOptions([...section3.options]);
    }
  }, [section3]);

  const isFoundArray = () => {
    return section3?.options.some(item => {
      return item.selected === true ? true : false;
    });
  };
  const isNotSelected = isFoundArray();


  const query = useQuery();

  const customerPref = async (datatopass: string) => {
    try {
      const result = await fetchPublicCustomerPref(datatopass);
      setSettingSections(result);
      setSections(result.sections);
      setIsLoading(false);
    } catch (err) {
      console.log('Error ', err);
      setIsLoading(false);
    }
  };

    //@ts-nocheck
    useEffect(() => {
      const custId = query.get('customerId');
      setCustId(custId);
    }, [query]);

  // const handleUpdateOptionCallback = (id: string) => {
  //   if (toggle) setToggle(false);
  //   setConfirm(true);
  //   handleUpdateOption(id);
  // };

  // const handleUpdateOption = (id: string) => {
  //   const optionIndexToUpdate = settingsState[2].options.findIndex(
  //     ({ optionId }) => id === optionId
  //   );

  //   const nextState = produce(settingsState, draftState => {
  //     draftState[2].options[optionIndexToUpdate].selected = !draftState[2]
  //       .options[optionIndexToUpdate].selected;
  //   });
  //   setOptions(nextState[2].options);
  //   setSettingsState(nextState);
  //   const isSelectedFalse = nextState
  //     ?.find(obj => obj.label === 'section3')
  //     ?.options.every(option => !option.selected);

  //   setIsToggle(isSelectedFalse ? true : false);
  //   setToggle(isSelectedFalse ? true : false);
  // };

  const handaleToggle = (e: any) => {
    setToggle(!toggle);
    setUnsubConfirmation(true) 
    if (e.target.checked) {
      setConfirm(true);
    } else {
      setOptions([...section3.options]);
    }
    // setToggle(!toggle);
    //setTimeout(() => {
    //
    // }, 500);
    // updateSubscription('unsubscribe');
  };

  // const handleUpdateSubscription = () => {

  //     updatePreferences(sections);
      
    
  // };

  const updatePublicPrefCallback = async (
    nextState: Array<SectionInterface>
  ) => {
    setIsLoading(true);
    try {
      await updatePublicCustomerPref(
        `${hashCustId}?customerId=${custId}`,
        nextState,
        custId,
        settingSections.id
      );
      await customerPref(`${hashCustId}?customerId=${custId}`);

      setIsLoading(false);
      setTimeout(() => {
        setShowSuccessMessage(true);
      }, 300);
    } catch (err) {
      setIsLoading(false);
    }
  };

  // const updatePreferences = (nextState: any) => {
  //   dispatch(updateUnsubscibeSettings(nextState, props));
  //   setTimeout(() => {
  //     setShowSuccessMessage(true);
  //   }, 800);
  // };

    //@ts-nocheck
    useEffect(() => {
      const id = props.pathName.split('/')[2];
      if (id && custId) {
        setHashCustId(id);
        setIsLoading(true);
        customerPref(`${id}?customerId=${custId}`);
      }
    }, [props.pathName, custId]);
    

  const updateSubscription = async (type: 'subscribe' | 'unsubscribe') => {
    if (type === 'unsubscribe') {
      try {
        await unSubscribePreferences(
          `${hashCustId}?customerId=${custId}`,
          type
        );
        customerPref(`${hashCustId}?customerId=${custId}`);
        setToastConfig({
          visible: true,
          message:
            "You've successfully unsubscribed from all our marketing communications.",
          type: 'SUCCESS'
        });
        setTimeout(() => {
          hideToast();
        }, 5000);
      } catch (err) {
        console.log(err);
        setToastConfig({
          visible: true,
          message: 'Error, Try Again',
          type: 'ERROR'
        });
        setTimeout(() => {
          hideToast();
        }, 5000);
      }
    }
  };

  const setSubscriptionMethod = async (type: 'subscribe' | 'unsubscribe') => {
    const res = await setSubscription(
      token as string,
      memberId as string,
      type
    );
    if (!res) {
      dispatch(fetchUserPreferences());
      if (type === 'unsubscribe') {
        setToastConfig({
          visible: true,
          message:
            "You've successfully unsubscribed from all our marketing communications.",
          type: 'SUCCESS'
        });
        setTimeout(() => {
          hideToast();
        }, 5000);
      }
    } else {
      setToastConfig({
        visible: true,
        message: res,
        type: 'ERROR'
      });
      setTimeout(() => {
        hideToast();
      }, 5000);
    }
  };

  const handleUpdateOption = (id: string) => {
    const optionIndexToUpdate = sections[2].options.findIndex(
      ({ optionId }) => id === optionId
    );

    const emailIndexToUpdate = sections[1].options.findIndex(
      ({ label }) => "Email" === label
    );

    const nextState = produce(sections, draftState => {
      draftState[2].options[optionIndexToUpdate].selected = !draftState[2]
        .options[optionIndexToUpdate].selected;
    });

    const newState = produce(nextState, draftState => {
      draftState[1].options[emailIndexToUpdate].selected = true
    });

    if(sections[1].options.every(option => !option.selected)){
      setSections(newState);
    }else{
      setSections(nextState);
    }

    
    if(id){
      setConfirm(true)
      setToggle(false);
    }
  };  

  return (
    <div className={classes.settingsContainer}>
      {isLoading ? <Loading /> : null}
      <Toast {...toastConfig} onClick={() => hideToast()} />
      <div className={classes.interests}>
        <section>
          <div aria-label={'unsubscibe'} tabIndex={0} role="textbox">
            <h1 aria-label="" className={classes.sectionHeading}>
              Update your preferences{' '}
              <img
                aria-label="image"
                src={editmail}
                style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                alt="Email-icon"
              />
            </h1>

            <p
              className={classes.sectionSummary}
              // aria-label={section2?.summary.label}
            >
              {
                'Below are your current preferences, you can tailor them so that we only contact you about our goods or services that you are interested in:'
              }
            </p>
          </div>
        </section>
        <div
          className={classes.interestGrid}
          aria-labelledby={section3?.summary.label}
        >
          {options.map(
            ({ optionId, label, value, selected }: OptionItemInterface) => {
              return (
                <div
                  className={classes.interest}
                  key={optionId}
                  role="checkbox"
                  aria-checked={selected}
                  tabIndex={0}
                >
                  <InterestCheckbox
                    id={optionId}
                    text={label}
                    value={value}
                    selected={selected}
                    onChange={() => handleUpdateOption(optionId)}
                  />
                </div>
              );
            }
          )}
        </div>

        <section>
          <div
            aria-label="Prefer not to hear from us"
            role={'group'}
            aria-labelledby={'Prefer not to hear from us? '}
          >
            <h2
              aria-label="Prefer not to hear from us?"
              className={classes.sectionHeading}
            >
              Or unsubscibe{' '}
            </h2>
            <p className={classes.sectionHeading}>
              {
                'You have opted to unsubscribe from an email we’ve sent you. Either unsubscribe from all email marketing messages or update your preferences above. '
              }
            </p>
            <div>
              <div
                className={classes.checkBoxContainer}
                role="checkbox"
                aria-checked={toggle}
                tabIndex={0}
                aria-label="Unsubscribe from all email marketing messages"
              >
                <Checkbox
                  id="hello"
                  text="Unsubscribe from all email marketing messages"
                  value={(!isNotSelected) || toggle}
                  onChange={e => handaleToggle(e)}
                  headerTextStyle={{
                    lineHeight: '22px',
                    flex: 1,
                    fontWeight: '600'
                  }}
                  disabled={!isNotSelected }
                />
              </div>
            </div>
          </div>
        </section>
        <ConfirmBox
          visible={shouldUpdate}
          cancelCallback={() => {
            window.location.reload();
          }}
          cancelText={'Cancel'}
          successCallback={() => {
            setConfirm(false);
            updatePublicPrefCallback(sections)
          }}
          successText="Confirm my changes"
        />

        <ConfirmBox
          visible={toggle && unsubConfirmation}
          cancelCallback={() => {
            setToggle(false);
            setConfirm(false)  
            setUnsubConfirmation(false) 
          }}
          cancelText={'Cancel'}
          successCallback={() => {
            setConfirm(false)
            updateSubscription('unsubscribe'); 
            setUnsubConfirmation(false)         
          }}
          successText="Confirm my changes"
        />

        <DialogSuccessBox
          visible={showSuccessMessage}
          contentText={'Your preferences have been updated!'}
          successText={'Close'}
          successCallback={() => {
            setShowSuccessMessage(false);
          }}
        />
        <ConfirmationPrompt visible={shouldUpdate || (toggle && unsubConfirmation)} />
      </div>
    </div>
  );
};

export default UnsubscribeContainer;
