import React, { useState, useLayoutEffect, useMemo, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../appState/rootReducer';
import { UiInterface } from '../../appState/uiSlice';
import { navigation } from '../../configs';
// import { ReactComponent as CoopLogo } from '../../assets/images/coop-logo-full.svg';
import { CoopHeroColor as CoopLogo } from '../../assets/images';
import classes from './NavigationDesktop.module.css';

interface ItemReferences {
  [key: string]: Link | null;
}

const NavigationDesktop: React.FC = () => {
  const { pathname } = useLocation();
  const [itemBorderStyle, setItemBorderStyle] = useState({ width: 0, left: 0 });
  const { view } = useSelector<RootState, UiInterface>(
    ({ uiSlice }) => uiSlice
  );
  const itemRefs = useRef<any>({});

  useLayoutEffect(() => {
    const key = pathname.split('/')[1] || 'home';

    const item = itemRefs.current[key];
    const indexItem = itemRefs.current['home'];

    let timeoutRef: NodeJS.Timeout;

    if (item && indexItem) {
      // setTimeout resolves a difference in the `left` value when the page loads
      timeoutRef = setTimeout(() => {
        // @ts-ignore
        const { left: startLeft } = indexItem.getBoundingClientRect();

        // @ts-ignore
        const { left, width } = item.getBoundingClientRect();
        setItemBorderStyle({ width, left: left - startLeft });
      }, 30);
    }

    return () => {
      if (timeoutRef) {
        clearTimeout(timeoutRef);
      }
    };
  }, [pathname, view]);

  const filteredNav = useMemo(() => {
    return navigation.filter(
      ({ hideDesktopNav, hide }) => !hideDesktopNav && !hide
    );
  }, []);

  return (
    <nav className={classes.navContainer} data-testid="desktop-nav">
      <CoopLogo className={classes.logo} />
      <div className={classes.navItems}>
        {filteredNav.map(({ text, path, externalLink }) => {
          const refKey = path.split('/')[1] || 'home';

          if (externalLink) {
            return (
              <a href={path} key={path} className={classes.navItem}>
                <span className={classes.navItemText}>{text}</span>
              </a>
            );
          }
          return (
            <Link
              to={path}
              key={path}
              className={
                path === pathname ? classes.navItemSelected : classes.navItem
              }
              ref={el => (itemRefs.current[refKey] = el)}
            >
              <span className={classes.navItemText}>{text}</span>
            </Link>
          );
        })}
        <Link to={'/logoutflow/'} className={classes.logoutButton}>
          Logout
        </Link>
        <div className={classes.borderFollow} style={itemBorderStyle} />
      </div>
    </nav>
  );
};

export default NavigationDesktop;
