// eslint-disable-next-line
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import { ListItemInterface } from '../types';
import * as api from '../api';
import jsondata from '../mock-data/categories.json';
import { shuffle } from '../utils/ArrayUtil';
import offersNewData from '../mock-data/offerNew.json';
import { useEffect } from 'react';

export interface OffersInterface {
  offersList: Array<ListItemInterface>;
  categoryList: Array<ListItemInterface>;
  categoryOffers: any;
  homeCategoryList: Array<ListItemInterface>;
  homeCategoryOffers: any;
  isLoader: boolean;
}

const initialState : OffersInterface = {
  offersList: [],
  categoryList: [],
  categoryOffers: {},
  homeCategoryList: [],
  homeCategoryOffers: {},
  isLoader: true,
};

const offersSlice = createSlice({
  name: 'offersSlice',
  initialState,
  reducers: {
    setOffersData(state, action: PayloadAction<OffersInterface>) {
      state.offersList = action?.payload.offersList;
      state.isLoader = action?.payload.isLoader;
    },
    setOffersCategoryData(state, action: PayloadAction<OffersInterface>) {
      state.categoryList = action.payload.categoryList;
      state.categoryOffers = { ...action.payload.categoryOffers };
      state.isLoader = action.payload.isLoader;
    },
    setHomeOffersCategoryData(state, action: PayloadAction<OffersInterface>) {
      state.homeCategoryList = action.payload.homeCategoryList;
      state.homeCategoryOffers = { ...action.payload.homeCategoryOffers };
      state.isLoader = action.payload.isLoader;
    }
  }
});

export const fetchOffers = (): AppThunk => async (dispatch, getState) => {
  const apiKey = 'fetchOffers';

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const offersData = await api.fetchOffers(`${token}` as string);
    //const offersData = offersNewData;
    const newoffersData = [] as any;

    const categoryOffers = {} as any;
    let categoryList = [] as any;

    let homeCategoryList = [] as any;
    const homeCategoryOffers = {} as any;

    if (offersData?.length) {
      offersData.forEach((item: any) => {
        item['isClaimed'] = item?.redemptionStatus === 'claimed';
        item['redeemed'] = item?.redemptionStatus === 'redeemed';

        let category = '';
        if (item?.offerCategory) {
          category = item?.offerCategory;
        }

        const offerCategoryOrder = item?.offerCategoryOrder;
        const categoryId = category
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '');
        const homePageCategory = item?.isHomePageOffer;
        const homePageCategoryOrder = item?.homePageCategoryOrder;

        const categoryItem = {} as any;
        categoryItem['categoryName'] = category;
        categoryItem['offerCategoryOrder'] = offerCategoryOrder;
        categoryItem['categoryId'] = categoryId;

        const homeCategoryItem = {} as any;
        homeCategoryItem['categoryName'] = category;
        homeCategoryItem['offerCategoryOrder'] = offerCategoryOrder;
        homeCategoryItem['categoryId'] = categoryId;
        homeCategoryItem['homePageCategory'] = homePageCategory;
        homeCategoryItem['homePageCategoryOrder'] = homePageCategoryOrder;

        categoryList.push(categoryItem);

        if (homeCategoryItem.homePageCategory) {
          homeCategoryList.push(homeCategoryItem);
        }

        if (!categoryOffers[categoryId]) {
          categoryOffers[categoryId] = [];
          categoryOffers[categoryId].push(item);
        } else {
          categoryOffers[categoryId].push(item);
        }

        if (!homeCategoryOffers[categoryId] && item.isHomePageOffer) {
          homeCategoryOffers[categoryId] = [];
          homeCategoryOffers[categoryId].push(item);
        } else if (item.isHomePageOffer) {
          homeCategoryOffers[categoryId].push(item);
        }

        newoffersData.push(item);
      });
    }

    categoryList.sort((a:any, b:any) => {
      if (a.offerCategoryOrder === undefined && b.offerCategoryOrder === undefined) {
        return 0; 
      } else if (a.offerCategoryOrder === undefined) {
        return 1; 
      } else if (b.offerCategoryOrder === undefined) {
        return -1; 
      } else {
        return a.offerCategoryOrder - b.offerCategoryOrder; 
      }
    });
    
    homeCategoryList.sort((a: any, b: any) => {
      if (a.homePageCategoryOrder === undefined && b.homePageCategoryOrder === undefined) {
        return 0;
      } else if (a.homePageCategoryOrder === undefined) {
        return 1;
      } else if (b.homePageCategoryOrder === undefined) {
        return -1;
      } else {
        return a.homePageCategoryOrder - b.homePageCategoryOrder;
      }
    });

    const getPriority = (offer: any) => offer.priority ?? Number.MAX_SAFE_INTEGER;

    for (const key in categoryOffers) {

      const sortedArray = categoryOffers[key].sort((a: any, b: any) => getPriority(a) - getPriority(b));
      categoryOffers[key] = [...sortedArray];
    }

    const categoryPriority: any = {};

    for (const key in categoryOffers) {
      const minCategoryOrder = Math.min(
        ...categoryOffers[key].map((offer: any) => offer.offerCategoryOrder ?? Number.MAX_SAFE_INTEGER)
      );

      categoryPriority[key] = minCategoryOrder;
    }

    for (const key in homeCategoryOffers) {
      if (homeCategoryOffers.hasOwnProperty(key)) {
        const sortedArray = homeCategoryOffers[key].sort((a: any, b: any) => {
          const aPriority = getPriority(a);
          const bPriority = getPriority(b);

          if (aPriority === bPriority) {
            return 0;
          }
          if (aPriority === Number.MAX_SAFE_INTEGER) {
            return 1;
          }
          if (bPriority === Number.MAX_SAFE_INTEGER) {
            return -1;
          }
          return aPriority - bPriority;
        });
        homeCategoryOffers[key] = [...sortedArray];
      }
    }
    categoryList = categoryList.filter(
      (obj: any, index: any, self: any) =>
        self.findIndex((o: any) => o.categoryId === obj.categoryId) === index
    );
    homeCategoryList = homeCategoryList.filter(
      (obj: any, index: any, self: any) =>
        self.findIndex((o: any) => o.categoryId === obj.categoryId) === index
    );

    const payload = {
      offersList: newoffersData,
      isLoader: newoffersData?false:true,
      categoryList:[],
      categoryOffers:null,
       homeCategoryList:[], 
       homeCategoryOffers:null
    };

    const isLoader = newoffersData?false:true;

    dispatch(offersSlice.actions.setOffersData(payload));
    //dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));

    const OffersCategoryDataPayload = {
      offersList: [],
      isLoader: newoffersData?false:true,
      categoryList,
      categoryOffers,
       homeCategoryList:[], 
       homeCategoryOffers:null
    };
    dispatch(
      offersSlice.actions.setOffersCategoryData(OffersCategoryDataPayload)
    );
    const HomeOffersCategoryDataPayload = {
      offersList: [],
      isLoader: newoffersData?false:true,
      categoryList,
      categoryOffers,
       homeCategoryList, 
       homeCategoryOffers
    };
    dispatch(
      offersSlice.actions.setHomeOffersCategoryData(HomeOffersCategoryDataPayload)
    );
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default offersSlice;
