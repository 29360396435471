// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kNSxSQ2pjkpzI1ZChRGuxA\\=\\= {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.kNSxSQ2pjkpzI1ZChRGuxA\\=\\= svg {\n  fill: currentColor;\n  display: block;\n}\n", "",{"version":3,"sources":["webpack://src/components/Icon/Icon.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[".iconContainer {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.iconContainer svg {\n  fill: currentColor;\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconContainer": "kNSxSQ2pjkpzI1ZChRGuxA=="
};
export default ___CSS_LOADER_EXPORT___;
