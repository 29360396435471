import jwt from 'jsonwebtoken';
import { fetchHandler } from './utils';

import {
  SettingsInterface,
  SectionInterface
} from './appState/userSettingsSlice';

// Customer Preference
export const fetchPublicCustomerPref = (datatopass: string) => {
  return fetch(
    `${process.env.REACT_APP_PREFERENCES_API_URL}/cxrewards/customer/view/${datatopass}`,
    {
      method: 'GET'
    }
  ).then(res => {
    return res.json();
  });
};
// Customer Preference
export const updatePublicCustomerPref = (
  datatopass: string,
  sectionsData: Array<SectionInterface>,
  customerid: string | null,
  id: string | null
) => {
  const requestData: SettingsInterface = {
    id,
    customerId: customerid,
    sections: sectionsData
  };
  return fetch(
    `${process.env.REACT_APP_PREFERENCES_API_URL}/cxrewards/customer/view/${datatopass}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    }
  );
};

export const unSubscribePreferences = (datatopass: string, type: string) => {
  console.log(
    'Calling API ',
    `${process.env.REACT_APP_PREFERENCES_API_URL}/cxrewards/customer/${type}/${datatopass}`
  );
  return fetch(
    `${process.env.REACT_APP_PREFERENCES_API_URL}/cxrewards/customer/${type}/${datatopass}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html'
      }
    }
  );
};

export const fetchMemberDetails = (token: string) => {
  return fetch(`${process.env.REACT_APP_API_URL}/member-details`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }).then(res => {
    if (res.ok) {
      return res.json();
    }

    throw res;
  });
};

export const fetchStampsData = (token: string) => {
  return fetchHandler(
    `${process.env.REACT_APP_STAMPS_API_URL}/cxrewards/points/customer/stamps`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );
};
export const fetchBulkData=(token: string) => {
  return fetchHandler(`${process.env.REACT_APP_BULK_DATA_API}/bulkcall`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const fetchOffers = (token: string) => {
  return fetchHandler(`${process.env.REACT_APP_API_URL}/offers`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const fetchCommunityOffers = (token: string) => {
  return fetchHandler(`${process.env.REACT_APP_API_URL}/community-hub-offers`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const fetchProgressionPoint = (token: string) => { //url to be changed
  return fetchHandler(`${process.env.REACT_APP_PREFERENCES_API_URL}/cxrewards/cds/sotp`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const fetchCharityOffers = (token: string) => {
  return fetchHandler(`${process.env.REACT_APP_API_URL}/charity-partner-offers`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const fetchUserSettings = (token: string) => {
  return fetchHandler(
    `${process.env.REACT_APP_PREFERENCES_API_URL}/cxrewards/customer/manage/preference`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );
};

export const updateUserPreferences = (
  token: string,
  requestData: SettingsInterface
) => {
  return fetch(
    `${process.env.REACT_APP_PREFERENCES_API_URL}/cxrewards/customer/manage/preference`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    }
  );
};

export const fetchUnsubscibeSettings = (customerId: any, memberId: any) => {
  return fetchHandler(
    `${process.env.REACT_APP_PREFERENCES_API_URL}/cxrewards/customer/view/${memberId}?customerId=${customerId}`,
    {
      method: 'GET',
      headers: { 
        'Content-Type': 'application/json'
      }
    }
  );
};

export const updateUnsubscibeSettings = (requestData:any) => { console.log("requestData",requestData);

  return fetch(
    `${process.env.REACT_APP_PREFERENCES_API_URL}/cxrewards/customer/view/${requestData?.memberId}?customerId=${requestData?.customerId}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    }
  );
};

export const logoutUser = (token: string | undefined, requestData: any) => {
  const searchParams = new URLSearchParams();
  for (const prop in requestData) {
    searchParams.set(prop, requestData[prop]);
  }
  return fetch(
    `${process.env.REACT_APP_KC_URL}/auth/realms/${process.env.REACT_APP_KC_REALM}/protocol/openid-connect/logout`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: searchParams
    }
  );
};


export const setSubscription = async (
  token: string,
  memberid: string | undefined,
  type: 'subscribe' | 'unsubscribe'
) => {
  let URL =
    type === 'subscribe'
      ? `${process.env.REACT_APP_SUBSCRIBE_API_URL}`
      : `${process.env.REACT_APP_UNSCBSCRIBE_API_URL}`;
  URL += `?customerId=${memberid}`;
  try {
    const res = await fetch(`${URL}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });
    if (res.status >= 200 && res.status < 400) {
      return null;
    } else {
      return 'Something went wrong. Please try again.';
    }
  } catch (error) {
    return 'Something went wrong. Please try again.';
  }
};

export const redeemReward = async (token: string, itemId: string) => {
  const URL = `${process.env.REACT_APP_REWARD_REDEEM_API_URL}`;
  const data = {
    itemId: itemId
  };
  try {
    const res = await fetch(`${URL}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    if (res.status >= 200 && res.status < 400) {
      return null;
    } else {
      return 'Something went wrong. Please try again.';
    }
  } catch (error) {
    return 'Something went wrong. Please try again.';
  }
};

export const eventTrackingCall = async (token: any) => {
  try {
    const URL = `${process.env.REACT_APP_EVENT_TRACKING_URL}`;
    const decoded = jwt.decode(token as string);

    const seconds = new Date().getTime() / 1000;
    const data = {
      //@ts-ignore
      member_id: decoded?.memberid, // current memberId
      event_name: 'login',
      timestamp: Math.floor(seconds), // current time in sec
      properties: {
        device: 'PC',
        os: window.navigator.platform, //eg. MacIntel, iPhone, Windows
        app_name: window.navigator.userAgent, // eg. Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.135 Safari/537.36
        app_version: window.navigator.appVersion, // eg. 5.0 (Macintosh; Intel Mac OS X 10_15_5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/84.0.4147.135 Safari/537.36
        activity: 'login'
      }
    };
    await fetch(`${URL}`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  } catch (error) {
    console.log('Error event tracking', error);
  }
};

export const fetchMemberBenefits = (token: string) => {
  return fetchHandler(`${process.env.REACT_APP_API_URL}/member-benefits`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const fetchRewards = (token: string) => {
  return fetchHandler(`${process.env.REACT_APP_API_URL}/rewards`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const fetchVoucher = (token: string) => {
  return fetchHandler(`${process.env.REACT_APP_VOUCHERS_API_URL}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const fetchTransactions = (token: string) => {
  return fetchHandler(
    `${process.env.REACT_APP_TRANSACTIONS_API_URL}/cxrewards/transactions/all?pageNo=0&pageSize=10`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );
};

export const fetchSessionDetails = (token: string) => {
  return fetchHandler(
    `${process.env.REACT_APP_TRANSACTIONS_API_URL}/cxrewards/oidc/sessions`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    }
  );
};

export const fetchMemberSavings = (token: string) => {
  return fetchHandler(`${process.env.REACT_APP_STAMPS_API_URL}/cxrewards/transactions/member-savings`, 
  {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
};

export const fetchMemberGUID = (token: string) => {
  return fetch(`${process.env.REACT_APP_GUID_API}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  }).then(res => {
    if (res.ok) {
      return res.json();
    }

    throw res;
  });
};