import React, { useMemo, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import classes from './Vouchers.module.css';
import { ReactComponent as CheckImageSVG } from '../../assets/images/check.svg';
import {
  VoucherInterface,
  VoucherItemInterface
} from '../../appState/voucherSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../appState/rootReducer';
import { isFloat } from '../../utils';
import VoucherDetail from '../../components/VoucherDetail/VoucherDetail';
import { BooleanLiteral } from 'typescript';
import { VOUCHER_TYPE } from '../../components/VoucherDetail/TermsCondition';
import redeemedIcon from '../../assets/images/redeemedIcon.png';
import Loading from '../../components/Loading/Loading';
import BackButton from '../../components/BackButton/BackButton';

interface Props {
  isStampsRewards?: boolean;
  isNative: boolean;
}
const Vouchers: React.FC<Props> = ({ isStampsRewards = false, isNative }) => {
  const { id: voucherId } = useParams<{ id: string }>();
  const { voucherList, isLoader } = useSelector<RootState, VoucherInterface>(
    ({ voucherSlice }) => voucherSlice
  );

  const currentVoucher = useMemo(() => {
    return voucherList.find(({ id }) => id === voucherId);
  }, [voucherId, voucherList]);

  const renderVoucherItem = (data: VoucherItemInterface) => {
    if (!data) return null;
    const isRedeemed = data.redeemed;
    const value = isFloat(data.voucherValue)
      ? Number(data.voucherValue).toFixed(2)
      : data.voucherValue;
    const voucherType = data?.voucherType.toLowerCase().trim() || '';
    return (
      <div
        className={`${classes.mainContainer} ${
          isRedeemed ? classes.disabledContainer : ''
        }`}
        key={data.id}
      >
        <div
          className={
            !isRedeemed
              ? VOUCHER_TYPE.SHARE_OF_PROFIT.includes(voucherType)
                ? classes.blueContainer
                : classes.pinkContainer
              : classes.greyContainer
          }
        >
          <div className={classes.leftContainer}>
            <h2
              className={
                !isRedeemed
                  ? VOUCHER_TYPE.SHARE_OF_PROFIT.includes(voucherType)
                    ? classes.valueText
                    : classes.valueTextPink
                  : classes.valueTextGrey
              }
            >
              {isRedeemed ? <div className={classes.overlay}></div> : null}
              {`£${value}`}
            </h2>
            {!isRedeemed ? (
              <div className={classes.voucherInfo}>
                <h4 className={`${classes.infoText} ${classes.infoTextHeader}`}>
                  {data.voucherType}
                </h4>
                <h4 className={classes.infoText}>
                  Expires <span>{data.expiryDate}</span>
                </h4>
              </div>
            ) : (
              <div className={classes.voucherInfo}>
                <h4 className={`${classes.infoText} ${classes.infoTextHeader}`}>
                  {data.voucherType}
                </h4>
                <h4 className={classes.infoText}>
                  Redeemed: <span>{data.redeemedDate}</span>
                </h4>
              </div>
            )}
          </div>
          <div className={classes.rightContainer}>
            {isRedeemed ? (
              <div className={classes.redeemedView}>
                <img alt="" src={redeemedIcon} />
                <p>{'Redeemed'}</p>
              </div>
            ) : (
              <div
                className={
                  VOUCHER_TYPE.SHARE_OF_PROFIT.includes(voucherType)
                    ? classes.ctaButtonVoucher
                    : classes.ctaButtonVoucherPink
                }
              >
                Redeem
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {' '}
      {voucherId ? (
        currentVoucher ? (
          <VoucherDetail data={currentVoucher} isNative={isNative} />
        ) : null
      ) : !isLoader ? (
        <>
          <div className={classes.page}>
            <h4 className={classes.heading}>
              Spend your vouchers in stores or online
            </h4>
            <div className={classes.content}>
              <section className={classes.sopSection}>
                <div className={classes.transactions}>
                  <div className={classes.list}>
                    {voucherList.length > 0 ? (
                      voucherList.map(item => {
                        if (item?.redeemed) return renderVoucherItem(item);
                        else
                          return (
                            <div className={classes.item} key={item.id}>
                              <Link to={`/voucher/${item.id}`} key={item.id}>
                                {renderVoucherItem(item)}
                              </Link>
                            </div>
                          );
                      })
                    ) : (
                      <div className={classes.noVoucher}>
                        <h2>No vouchers to display</h2>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
  // else return null;
};

export default Vouchers;
