import React from 'react';
import classes from './DialogBox.module.css';

interface Props {
  visible?: boolean | undefined;
  cancelText: string;
  successText: string;
  headerText: string;
  contentText: string;
  successCallback(): void;
  cancelCallback(): void;
}
const DialogBox = ({
  visible,
  successCallback,
  cancelCallback,
  cancelText,
  successText,
  headerText,
  contentText
}: Props) => {

  return (
    <>
      {visible ? (
        <div className={classes.mainContainer}>
          <div className={classes.dialogcontainer}>
            <h3 className={classes.header}>{headerText}</h3>
            <h4 className={classes.content}>{contentText}</h4>
            <div className={classes.buttonContainer}>
              <button className={classes.buttonFirst} onClick={cancelCallback}>
                {cancelText}
              </button>
              <button
                className={classes.buttonSecond}
                onClick={successCallback}
              >
                {successText}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default DialogBox;
