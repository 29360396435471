import React from 'react';
import classes from './InterestCheckbox.module.css';
import InterestIcon from './InterestIcon';
import { ActiveTick, InActiveTick } from '../../assets/images/index';

interface InterestCheckboxProps {
  id: string;
  text: string;
  value: string;
  selected: boolean;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
}

const InterestCheckbox: React.FC<InterestCheckboxProps> = ({
  text,
  id,
  value,
  selected,
  onChange
}) => {
  const Icon = (selected && ActiveTick) || InActiveTick;
  return (
    <label
      className={classes.checkbox}
      htmlFor={id}
      data-testid="interest-checkbox"
      data-cy="interest-checkbox"
    >
      <input
        className={classes.input}
        type="checkbox"
        id={id}
        checked={selected}
        onChange={onChange}
      />
      <div className={classes.background} />
      <div className={classes.icon}>
        <InterestIcon iconName={value} />
      </div>
      <span className={classes.text}> {text}</span>
      <div className={classes.tick}>
        <Icon />
      </div>
    </label>
  );
};

export default InterestCheckbox;
