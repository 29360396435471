/* eslint-disable react/display-name */
import React, { Component } from 'react';
import classes from './cookie-policy.module.css';

const termsJson = [
  {
    header: 'Introduction',
    description: [
      {
        type: 'text',
        text:
          'This Policy has been prepared by the Midcounties Co-operative Limited, a society under the Co-operative and Community Benefit Societies Act 214, with registration number 19025R.'
      },
      {
        type: 'text',
        text:
          'This Cookies Policy contains all the information about cookies that [MCC] uses on its website or mobile app, namely:'
      },
      {
        type: 'link',
        href: 'https://membership.yourcoop.coop/',
        text: 'https://membership.yourcoop.coop/'
      },
      {
        type: 'link',
        href:
          'https://play.google.com/store/apps/details?id=com.cxloyalty.midcountiescoop',
        text:
          'https://play.google.com/store/apps/details?id=com.cxloyalty.midcountiescoop'
      }
    ]
  },
  {
    header: 'What Are Cookies',
    description: [
      {
        type: 'text',
        text:
          'Cookies are text files containing small amounts of information which are downloaded to your browser/device when you visit a website/app. It allows the website/app to recognise that user’s device and software on the user’s device (for example a web browser) can store cookies and send them back to the website the next time the user visits the website.'
      },
      {
        type: 'text',
        text:
          'We will never store your personal data in a plain text cookie. We may store personal data in a secure, encrypted cookie, which can only be read by our servers.'
      },
      {
        type: 'text',
        text: 'We use the following types of cookies:'
      },
      {
        type: 'node',
        node: () => (
          <span>
            <strong>Personalization & Analytics:</strong> These cookies enable
            the website to provide personalisation and enhanced functionality
            based on your browsing history. They may be set by us or by third
            party providers whose services have been added to our website. If
            you do not authorise these cookies, we will not be able to provide
            you a personalized experience.
          </span>
        )
      },
      {
        type: 'node',
        node: () => (
          <span>
            <strong>Necessary Cookies:</strong> These cookies are necessary for
            the App/website to function and cannot be switched off in our
            systems. They are usually only set in response to actions made by
            you which amount to a request for services, such as setting your
            privacy preference, logging in or filling in forms
          </span>
        )
      },
      {
        type: 'node',
        node: () => (
          <span>
            <strong>Performance Cookies:</strong> These cookies collect
            information about how visitors use the App, for example, the number
            of visitors, which pages are the most and least popular and how
            visitors move around the App. All information collected by these
            cookies is aggregated and therefore anonymous. These cookies help us
            measure and improve the performance of the App. If you do not allow
            these cookies, we will not be able to monitor the website
            performance.
          </span>
        )
      }
    ]
  },
  {
    header: 'Cookies Used on Our Website',
    description: [
      {
        type: 'table',
        tableData: {
          headers: ['Cookie Name', 'Type', 'Purpose'],
          data: [
            {
              'Cookie Name': 'OptanonAlertBoxClosed',
              Type: 'Necessary',
              Purpose:
                'This cookie is set by websites using certain versions of the cookie law compliance solution from OneTrust. It is set after visitors have seen a cookie information notice and, in some cases, only when they actively close the notice down. It enables the website not to show the message more than once to a user. The cookie has a one-year lifespan and contains no personal information.'
            },
            {
              'Cookie Name': 'Matomo/AWSALB',
              Type: 'Personalization & Analytics',
              Purpose:
                'To track the pages user goes to, how long they spend in website etc.'
            },
            {
              'Cookie Name': 'OptanonConsent',
              Type: 'Necessary',
              Purpose:
                'This cookie is set by the cookie compliance solution from OneTrust. It stores information about the categories of cookies the site uses and whether visitors have given or withdrawn consent for the use of each category. This enables site owners to prevent cookies in each category from being set in the user’s browser when consent is not given. The cookie has a normal lifespan of one year, so that returning visitors to the site will have their preferences remembered. It contains no information that can identify the site visitor.'
            },
            {
              'Cookie Name': 'KC_RESTART',
              Type: 'Necessary',
              Purpose: 'used by keycloak'
            },
            {
              'Cookie Name': '.AspNetCore.Antiforgery',
              Type: 'Necessary',
              Purpose: '.AspNetCore.'
            },
            {
              'Cookie Name': 'AUTH_SESSION_ID',
              Type: 'Necessary',
              Purpose: 'used by keycloak to store auth session id'
            }
          ]
        }
      }
    ]
  },
  {
    header: 'Cookies Used on Our Mobile App',
    description: [
      {
        type: 'table',
        tableData: {
          headers: ['Crashaytics', 'Performance', 'Crash reporting'],
          data: [
            {
              Crashaytics: 'Firebase Analytics',
              Performance: 'Personalization & Analytics',
              'Crash reporting': 'App downloads, daily active users'
            },
            {
              Crashaytics: 'Firebase Performance',
              Performance: 'Performance',
              'Crash reporting': 'Are any users experiencing bad performance'
            },
            {
              Crashaytics: 'Matomo',
              Performance: 'Personalization & Analytics',
              'Crash reporting':
                'To track the pages user goes to, how long they spend in app etc.'
            },
            {
              Crashaytics: 'Exponea push notifications',
              Performance: 'Necessary',
              'Crash reporting':
                'fires push notifications if user has them turned on'
            },
            {
              Crashaytics: 'Exponea tracking',
              Performance: 'Personalization & Analytics',
              'Crash reporting':
                'Tracking of login events, basket events, registration, push notifications for scenarios'
            }
          ]
        }
      }
    ]
  },
  {
    header: 'Third- Party Cookies Used on Our Website',
    description: [
      {
        type: 'table',
        tableData: {
          headers: ['Cookie Name', 'Type', 'Purpose'],
          data: [
            {
              'Cookie Name': '_ga',
              Type: 'Personalisation & Analytics',
              Purpose: `This cookie name is associated with Google Universal Analytics - which is a significant update to Google's more commonly used analytics service. This cookie is used to distinguish unique users by assigning a randomly generated number as a client identifier. It is included in each page request in a site and used to calculate visitor, session, and campaign data for the site’s analytics reports. By default, it is set to expire after 2 years, although this is customisable by website owners.`
            },
            {
              'Cookie Name': '_gid',
              Type: 'Personalisation & Analytics',
              Purpose: `This cookie name is associated with Google Universal Analytics. This appears to be a new cookie and as of Spring 2017 no information is available from Google. It appears to store and update a unique value for each page visited.`
            },
            {
              'Cookie Name': '_gat_gtag_xxxxxxxxxxxxxxxxxxxxxxxxxxx',
              Type: 'Personalisation & Analytics',
              Purpose: `Google Analytics`
            }
          ]
        }
      }
    ]
  },
  {
    header: 'How We Use Cookies',
    description: [
      {
        type: 'text',
        text:
          'We use cookies for a variety of reasons. Unfortunately, in most cases there are no industry standard options for disabling cookies without completely disabling the functionality and features they add to Our Websites. It is recommended that you leave on all cookies if you are not sure whether you need them or not in case, they are used to provide a service that you use.'
      }
    ]
  },
  {
    header: 'Disabling Cookies',
    description: [
      {
        type: 'text',
        text:
          'You may refuse to accept, or withdraw your previous consent to the cookies installed on your computer or device in two ways:'
      },
      {
        type: 'node',
        node: () => {
          return (
            <ul>
              <li>
                You can do so by visiting the Privacy Preference Centre, which
                can be accessed through the ‘Cookies Settings’ link (which can
                be found in the footer of all our web pages). You may change
                your mind about the cookies you have selected through the
                Privacy Preference Centre at any time. Please be aware that the
                Privacy Preference Centre cannot be used to block cookies on
                third party websites linked to from our website. To remove these
                cookies, you will need to visit the relevant third-party site
                and follow the instructions in their cookie notices.
                Alternatively, follow the instructions below to turn off these
                cookies using your browser settings
              </li>
              <li>
                Your browser settings may allow you to refuse the setting of
                certain cookies. The “Help” or “Internet Settings” functions
                within your browser should tell you how. For information on how
                to manage cookies on popular browsers please see the following
                links:
                <ul>
                  <li>Google Chrome</li>
                  <li>Microsoft Edge</li>
                  <li>Mozilla Firefox</li>
                  <li>Microsoft Internet Explorer</li>
                  <li>Opera</li>
                  <li>Apple Safari</li>
                </ul>
              </li>
            </ul>
          );
        }
      },
      {
        type: 'text',
        text:
          'About third-party cookies, we want to make sure that you understand that once you have allowed their use and installation on your computer, if you later decide to block them, this means that we will no longer receive data that could be collected by these cookies.'
      },
      {
        type: 'text',
        text:
          'This does not mean that these cookies are permanently uninstalled from your terminal, for this it will be necessary that you go to the browser settings and follow the instructions to that effect.'
      }
    ]
  },
  {
    header: 'More Information',
    description: [
      {
        type: 'text',
        text:
          'We reserve the right to modify this cookies policy at any time by posting the amended terms on polithe website. All amended terms will automatically take effect immediately on posting, so please review it periodically on the website to inform yourself of any changes. If you do not accept the changes made, you should immediately stop using the website or amend your cookie settings accordingly.'
      }
    ]
  }
];

export default class CookiePolicy extends Component {
  render() {
    return (
      <div className={classes['cookie-policy-container']}>
        <div className={classes.section}>
          <div className={classes.cookieContainer}>
            <section className={classes.content}>
              <h1 className={classes.sectionHeading}>Cookie Policy</h1>
              <p className={classes.version}>
                Version 2.7 Last Updated 17 Sep 2021
              </p>
              <>
                {termsJson.map((term: any, index) => {
                  return (
                    <div key={index}>
                      <p>
                        <strong>{`${index + 1} ${term.header}`}</strong>
                      </p>
                      {term.description.map((descr: any) => {
                        let item;
                        switch (descr.type) {
                          case 'text':
                            item = <p className={classes.para}>{descr.text}</p>;
                            break;
                          case 'link':
                            item = (
                              <p className={classes.para}>
                                <a href={descr.href}>{descr.text}</a>{' '}
                              </p>
                            );
                            break;
                          case 'table':
                            item = (
                              <table>
                                <tr>
                                  {descr.tableData.headers.map((i: string) => (
                                    <th key={i}>{i}</th>
                                  ))}
                                </tr>
                                <tbody>
                                  {descr.tableData.data.map(
                                    (d: any, tIndex: number) => {
                                      return (
                                        <tr key={tIndex}>
                                          {descr.tableData.headers.map(
                                            (k: string) => {
                                              return <td key={k}>{d[k]}</td>;
                                            }
                                          )}
                                        </tr>
                                      );
                                    }
                                  )}
                                </tbody>
                              </table>
                            );
                            break;
                          case 'node':
                            item = (
                              <p className={classes.para}>{descr.node()}</p>
                            );
                            break;
                          default:
                            item = <p className={classes.para}>{descr.text}</p>;
                        }
                        return item;
                      })}
                    </div>
                  );
                })}
              </>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
