import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../Icon/Icon';
import BurgerButton from '../BurgerButton/BurgerButton';
import { navigation } from '../../configs';
//import { ReactComponent as CoopLogo } from '../../assets/images/coop-logo-full.svg';
import classes from './NavigationMobile.module.css';
import { CoopHeroColor as CoopLogo } from '../../assets/images';

const NavigationMobile: React.FC = () => {
  const { pathname } = useLocation();
  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    setShowNav(false);
  }, [pathname]);

  return (
    <nav className={classes.navContainer} data-testid="mobile-nav">
      <header className={showNav ? classes.headerShowNav : classes.header}>
        <CoopLogo className={classes.logo} />
        <div className={classes.burgerButtonContainer}>
          <BurgerButton
            isExpanded={showNav}
            onClick={() => setShowNav(!showNav)}
          />
        </div>
      </header>
      <div
        className={
          showNav ? classes.contentOverlayVisible : classes.contentOverlay
        }
        aria-hidden={showNav?false:true}
      />
      <div
        className={showNav ? classes.navItems : classes.navItemsHiddenMobile}
        aria-hidden={showNav?false:true}

      >
        {navigation
          .filter(({ hide }) => !hide)
          .map(({ text, path, iconName, externalLink }) => {
            if (externalLink) {
              return (
                <a href={path} key={path} className={classes.navItem}>
                  <div className={classes.iconContainer}>
                    <Icon iconName={iconName} />
                  </div>
                  <span className={classes.navItemText}>{text}</span>
                </a>
              );
            }
            return (
              <Link
                to={path}
                key={path}
                className={
                  path === pathname ? classes.navItemSelected : classes.navItem
                }
              >
                <div className={classes.iconContainer}>
                  <Icon isSelected={path === pathname} iconName={iconName} />
                </div>
                <span className={classes.navItemText}>{text}</span>
              </Link>
            );
          })}
        <Link to={'/logoutflow/'} className={classes.logoutButton}>
          Logout
        </Link>
      </div>
    </nav>
  );
};

export default NavigationMobile;
