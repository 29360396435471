import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import BackButton from '../../components/BackButton/BackButton';
import { RootState } from '../../appState/rootReducer';
import { UiInterface } from '../../appState/uiSlice';
import ClipboardJS from 'clipboard';

import classes from './MemberSavings.module.css';
import { MemberSaveInterface } from '../../appState/MemberSavingsSlice';

interface Props {
  isNative: boolean;
  data?: MemberSaveInterface;
  showToast?: (message: string, isSuccess: boolean) => void;
  isFullWidthHeader?: boolean;
}

const MemberSavings: React.FC<Props> = ({
  data,
  isNative,
  showToast,
  isFullWidthHeader = false
}) => {
  const canvasRef = useRef(null);
  const [windowWidth, setWindowwidth] = useState(0);
  const { memberSavingsDataList } = useSelector<RootState, MemberSaveInterface>(
    ({ memberSavingSlice }) => memberSavingSlice
  );

  const valueConversion = (poundValue: any) => {
    if (poundValue < 1) {
      return `${poundValue * 100}p`;
    } else if (/\./.test(poundValue)) {
      return String.fromCharCode(163) + poundValue.toFixed(2);
    } else {
      return String.fromCharCode(163) + poundValue;
    }
  };

  const brandColorStyle = (foodType: any) => {
    switch (foodType) {
      case 'Co-op Food':
        return '#5c7d5f';
      case 'travel':
        return '#F88D2A';
      case 'childcare':
        return '#c50068';
      case 'broadband':
        return '#108986';
      default:
        return '#5C7D5T';
    }
  };

  return (
    <>
      <div className={classes.page}>
        <div className={classes.pageBackbtn}>
          {' '}
          <BackButton />
        </div>
        <h3 className={classes.heading}>Member Savings</h3>
        <div className={classes.content}>
          <div className={classes.memberSaveSection}>
            <h3>Your last 12 months in review</h3>
            <div className={classes.details}>
              <span className={classes.title}>
                <span>{'As a member you’ve saved:'}</span>
              </span>
              <span className={classes.value}>
                {valueConversion(memberSavingsDataList.totalSavings)}
              </span>
            </div>
          </div>
          <section className={classes.mainSection}>
            <div className={classes.mainContainer}>
              {memberSavingsDataList?.savings?.map(
                (
                  item: {
                    savingsType: any;
                    savingsValue: any;
                    savingsColorCode: any;
                  },
                  index: React.Key | null | undefined
                ) => (
                  <div className={classes.itemContainer} key={index}>
                    <div className={classes.leftContainer}>
                      <div>
                        <span
                          className={classes.tradingGroup}
                          style={{
                            backgroundColor: brandColorStyle(
                              item?.savingsType || null
                            )
                          }}
                        ></span>
                      </div>
                      <span className={classes.title}>
                        {item?.savingsType.charAt(0).toUpperCase() +
                          item?.savingsType.slice(1)}
                      </span>
                    </div>
                    <div className={classes.rightContainer}>
                      <span className={classes.value1}>
                        {valueConversion(item?.savingsValue)}
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default MemberSavings;
