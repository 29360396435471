import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import { ListItemInterface } from '../types';
import * as api from '../api';

export interface MemberBenefitsInterface {
  memberBenefitsList: Array<ListItemInterface>;
}

const initialState: MemberBenefitsInterface = {
  memberBenefitsList: []
};

const memberBenefitsSlice = createSlice({
  name: 'memberBenefitsSlice',
  initialState,
  reducers: {
    setMemberBenefitsData(state, action: PayloadAction<ListItemInterface[]>) {
      state.memberBenefitsList = action.payload;
    }
  }
});

export const fetchMemberBenefits = (): AppThunk => async (
  dispatch,
  getState
) => {
  const apiKey = 'fetchMemberBenefits';

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const memberBenefitsData = await api.fetchMemberBenefits(token as string);
    const newMemberBenefitsData = [] as any;
    if (memberBenefitsData?.length) {
      memberBenefitsData.forEach((item: any) => {
        item['isClaimed'] = item?.redemptionStatus === 'claimed';
        item['redeemed'] = item?.redemptionStatus === 'redeemed';
        newMemberBenefitsData.push(item);
      });
    }
    dispatch(
      memberBenefitsSlice.actions.setMemberBenefitsData(newMemberBenefitsData)
    );
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default memberBenefitsSlice;
