import React from 'react';
import classes from './BurgerButton.module.css';

interface BurgerButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isExpanded: boolean;
}

const BurgerButton: React.FC<BurgerButtonProps> = ({ onClick, isExpanded }) => {
  return (
    <button
      className={
        isExpanded ? classes.burgerButtonExpanded : classes.burgerButton
      }
      onClick={onClick}
      data-testid="burger-button"
      type="button"
      aria-label=""
    >
      <span />
    </button>
  );
};

export default BurgerButton;
