import React from 'react';
import BlockingError from '../BlockingError/BlockingError';
import ActionButton from '../ActionButton/ActionButton';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // error
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error('Error boundary log:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <BlockingError
          heading="Oops!"
          message="Sorry, something went wrong."
          cta={
            <ActionButton
              text="Click here to go back"
              href="/"
              onClick={() => this.setState({ hasError: false })}
            />
          }
        />
      );
    }

    return this.props.children;
  }
}
