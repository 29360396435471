import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import JsBarcode from 'jsbarcode';
// import { ReactComponent as CoopLogo } from '../../assets/images/coop-logo-full.svg';
import { CoopHeroColor as CoopLogo } from '../../assets/images';

import BackButton from '../../components/BackButton/BackButton';
import { RootState } from '../../appState/rootReducer';
import { UiInterface } from '../../appState/uiSlice';
import ClipboardJS from 'clipboard';

import classes from './VoucherDetail.module.css';
import ActionButton from '../ActionButton/ActionButton';
import { ReactComponent as BannerCirclesLeft } from '../../assets/images/circles-left.svg';
import { ReactComponent as BannerCirclesRight } from '../../assets/images/circles-right.svg';
import { useReactToPrint } from 'react-to-print';
import { VoucherItemInterface } from '../../appState/voucherSlice';
import voucherLogo from '../../assets/images/voucherlogo1.png';

import {
  TermsCondition,
  About,
  HowToRedeem,
  VOUCHER_TYPE
} from './TermsCondition';
import { isFloat } from '../../utils';
import moment from 'moment';
import { Redirect as CustomRedirect} from 'react-router-dom';

interface Props {
  isNative: boolean;
  data?: VoucherItemInterface;
  showToast?: (message: string, isSuccess: boolean) => void;
  isFullWidthHeader?: boolean;
}

const Redirect: React.FC<PropsWithChildren<{ to: string }>> = CustomRedirect as unknown as React.FC<PropsWithChildren<{ to: string }>>;



const signleUseNote = 'Single use only in Midcounties Co-operative';
// const copy = 'Midcounties Co-operative';

const VoucherDetail: React.FC<Props> = ({
  data,
  isNative,
  showToast,
  isFullWidthHeader = false
}) => {
  const canvasRef = useRef(null);
  const [windowWidth, setWindowwidth] = useState(0);
  const { view } = useSelector<RootState, UiInterface>(
    ({ uiSlice }) => uiSlice
  );
  const voucherCode = data?.barcode;
  let currentClipboard: any = null;

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  const handleResize = () => {
    setWindowwidth(window.innerWidth);
    initializeClipboard();
  };

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  useEffect(() => {
    if (canvasRef.current && voucherCode) {
      let width = 2,
        fontSize = 12;

      if (windowWidth < 450) {
        width = 1.7;
        if (windowWidth < 350) {
          width = 1.5;
          fontSize = 16;
        }
      }

      JsBarcode(canvasRef.current, voucherCode, {
        height: 120,
        width,
        displayValue: true,
        fontSize,
        background: 'transparent',
        marginLeft: 0
      });
    }
  }, [windowWidth, voucherCode]);

  const initializeClipboard = () => {
    if (!currentClipboard) {
      currentClipboard = new ClipboardJS('#copy-button');
      //@ts-ignore
      currentClipboard.on('success', function(e) {
        e.clearSelection();
        showToast && showToast('Voucher code copied!', true);
      });
      //@ts-ignore
      currentClipboard.on('error', function(e) {
        showToast && showToast('Failed to copy voucher code!', false);
        return false;
      });
    }
  };

  if (!data || data?.status !== 'available' || data?.redeemed) {
    return <Redirect to={'/vouchers'} />;
  }

  if (!data || data?.redeemed) return null;
  const voucherType = data?.voucherType.toLowerCase().trim() || '';
  let typeOfVoucher = [
    VOUCHER_TYPE.SHARE_OF_PROFIT,
    VOUCHER_TYPE.THANK_YOU,
    VOUCHER_TYPE.MEMBER_THANK_YOU
  ].includes(voucherType)
    ? voucherType
    : VOUCHER_TYPE.SHARE_OF_PROFIT;
  typeOfVoucher = [
    VOUCHER_TYPE.THANK_YOU,
    VOUCHER_TYPE.MEMBER_THANK_YOU
  ].includes(voucherType)
    ? VOUCHER_TYPE.THANK_YOU
    : typeOfVoucher;
  const value = isFloat(data?.voucherValue)
    ? Number(data?.voucherValue).toFixed(2)
    : data?.voucherValue;
  typeOfVoucher = typeOfVoucher.toLowerCase();
  const isSOPNov2021Voucher =
    typeOfVoucher === VOUCHER_TYPE.SHARE_OF_PROFIT.toLowerCase()
      ? moment(data.created).month() === 10 &&
        moment(data.created).year() === 2021
      : false;
  return (
    <div className={classes.detailContent}>
      <div
        style={{
          position: 'sticky',
          top: '-1px',
          backgroundColor: '#ffffff'
        }}
      >
        {' '}
        <BackButton />
      </div>
      {data.showBarcode ? (
        <h5
          aria-label="To redeem in a Food store – scan this barcode in one of our Food stores​"
          className={classes.voucherOtherInfo}
        >
          To redeem in a Food store
          <br />
          scan this barcode at the till
        </h5>
      ) : (
        <h5
          aria-label="To redeem online, enter this code during checkout​"
          className={classes.voucherOtherInfo}
        >
          To redeem online,
          <br />
          enter this code during checkout
        </h5>
      )}
      <div className={classes.content}>
        <div className={classes.list}>
          <div className={classes.desktopRow} ref={componentRef}>
            <section className={classes.cell}>
              {
                // Uncomment after testing....... to see barcode in voucher detail
                // Add this line after testing  data.showBarcode && isNative ? (
                //remove && isNative
              }
              {data.showBarcode && isNative? (
                <>
                  <div className={classes.dottedBorder}>
                    <div className={classes.barCodeContainer}>
                      <div
                        className={classes.voucherHeader}
                        aria-label={`£${value}`}
                      >
                        <h1>{`£${value}`}</h1>
                        <img
                          aria-label="banner"
                          src={voucherLogo}
                          alt={'bannerImage'}
                          role="banner"
                        />
                      </div>

                      <div
                        aria-label="Vouchers Barcode for scanning"
                        style={{ textAlign: 'center' }}
                      >
                        <div className={classes.voucherHeaderDate}>
                          <div
                            className={classes.infoCells}
                            style={{ textAlign: 'justify' }}
                            aria-label={`Voucher opened on ${moment().format(
                              'DD/MM/YYYY'
                            )}`}
                          >
                            <h5>Voucher opened on </h5>

                            <span>{`${moment().format('DD/MM/YYYY')}`}</span>
                          </div>
                          <div
                            className={classes.infoCells}
                            aria-label={`valid until ${data?.expiryDate}`}
                            style={{ textAlign: 'right' }}
                          >
                            <h5>{'Valid until'}</h5>
                            <span>{data?.expiryDate}</span>
                          </div>
                        </div>
                        <svg
                          ref={canvasRef}
                          className={classes.barCode}
                          aria-labelledby="cardTitle cardDesc"
                          role="img"
                        >
                          <title id="cardTitle">
                            {`Vouchers Barcode for scanning ${voucherCode
                              ?.split('')
                              .join(', ')}`}
                          </title>
                          <desc id="cardDesc">
                            Vouchers Barcode for scanning
                          </desc>
                        </svg>
                      </div>
                      <div className={classes.note}>{signleUseNote}</div>
                    </div>
                  </div>
                </>
              ) : isNative ? (
                <>
                  <div className={classes.dottedBorder}>
                    <div className={classes.barCodeContainer}>
                      <div
                        className={classes.voucherHeader}
                        aria-label={`£${value}`}
                      >
                        <h1>{`£${value}`}</h1>
                        <img
                          aria-label="banner"
                          src={voucherLogo}
                          alt={'bannerImage'}
                          role="banner"
                        />
                      </div>

                      <div
                        aria-label="Vouchers Barcode for scanning"
                        style={{ textAlign: 'center' }}
                      >
                        <div className={classes.voucherHeaderDate}>
                          <div
                            className={classes.infoCells}
                            style={{ textAlign: 'justify' }}
                            aria-label={`Voucher opened on ${moment().format(
                              'DD/MM/YYYY'
                            )}`}
                          >
                            <h5>Voucher opened on </h5>

                            <span>{`${moment().format('DD/MM/YYYY')}`}</span>
                          </div>
                          <div
                            className={classes.infoCells}
                            aria-label={`valid until ${data?.expiryDate}`}
                            style={{ textAlign: 'right' }}
                          >
                            <h5>{'Valid until'}</h5>
                            <span>{data?.expiryDate}</span>
                          </div>
                        </div>
                        <div className={classes.alphaCode}>
                          <h5>{data?.barcodeAlpha}</h5>
                        </div>
                      </div>
                      <div className={classes.note}>{signleUseNote}</div>
                    </div>
                  </div>
                </>
              ) : null}

              {data.showBarcode ? ( //data.showBarcode
                <div className={`${classes.cta} ${classes.desktopOnly}`}>
                  <ActionButton
                    text={'Print Voucher'}
                    lightBackground
                    onClick={handlePrint}
                  />
                </div>
              ) : null}
            </section>
            <div className={classes.termsCond}>
              <TermsCondition
                type={typeOfVoucher}
                voucherType={voucherType}
                isNovSOP={isSOPNov2021Voucher}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoucherDetail;
