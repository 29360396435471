import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import * as api from '../api';
import offersSlice from './offersSlice';
import memberBenefitsSlice from './memberBenefitsSlice';
import rewardsSlice from './rewardsSlice';
import stampsSlice from './stampsSlice';
import voucherSlice from './voucherSlice';
import moment from 'moment';
import memberDetailsSlice from './memberDetailsSlice';
import transactionsSlice from './transactionsSlice';

export interface BulkDataInterface {
  bulkData: string;
}

const initialState = {
  bulkData: ''
};

const bulkSlice = createSlice({
  name: 'bulkSlice',
  initialState,
  reducers: {
    setBulkData(state, action: PayloadAction<string>) {
      state.bulkData = action.payload;
    }
  }
});

export const fetchBulkData = (): AppThunk => async (dispatch, getState) => {
  const apiKey = 'fetchBulkData';

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const bulkData = await api.fetchBulkData(`${token}` as string);
    //Offers
    const newoffersData = [] as any;
    if (bulkData && Object.keys(bulkData).length > 0) {
      const offersData = bulkData['offers'];
      const memberBenefitsData = bulkData['memberBenefits'];
      const rewardsData = bulkData['rewards'];
      const stampsData = bulkData['stamps'];
      const voucherData = bulkData['vouchers'];
      const memberDetails=bulkData["memberDetails"];
      const transactionsAll=bulkData["transactionsAll"];

      
      if (offersData?.length) {
        offersData.forEach((item: any) => {
          item['isClaimed'] = item?.redemptionStatus === 'claimed';
          item['redeemed'] = item?.redemptionStatus === 'redeemed';
          newoffersData.push(item);
        });
      }
      dispatch(offersSlice.actions.setOffersData(newoffersData));

      //Member Benefits
      const newMemberBenefitsData = [] as any;
      if (memberBenefitsData?.length) {
        memberBenefitsData.forEach((item: any) => {
          item['isClaimed'] = item?.redemptionStatus === 'claimed';
          item['redeemed'] = item?.redemptionStatus === 'redeemed';
          newMemberBenefitsData.push(item);
        });
      }
      dispatch(
        memberBenefitsSlice.actions.setMemberBenefitsData(newMemberBenefitsData)
      );

      //"rewards"
      const newRewardsData = [] as any;
      if (rewardsData?.length) {
        rewardsData.forEach((item: any) => {
          item['isClaimed'] = item?.redemptionStatus === 'claimed';
          item['redeemed'] = item?.redemptionStatus === 'redeemed';
          item['endDate'] = item?.endDate
            ? moment.unix(item.endDate).format('DD/MM/YYYY')
            : null;

          newRewardsData.push(item);
        });
      }
      dispatch(rewardsSlice.actions.setRewardsData(newRewardsData));

      //stamps
      dispatch(stampsSlice.actions.setStampsData(stampsData));

      //vouchers
      const newVoucherData = [] as any;
      const isCampaignActive = !!voucherData;
      if (voucherData?.length) {
        let id = 1000;
        voucherData.forEach((item: any) => {
          id++;
          item['id'] = id.toString();
          item['redeemed'] = item?.status === 'redeemed';
          item['expiryDate'] = item?.expiryDate
            ? moment(item.expiryDate).format('DD/MM/YYYY')
            : null;
          item['redeemedDate'] = item?.redeemedDate
            ? moment(item.redeemedDate).format('DD/MM/YYYY')
            : null;

          newVoucherData.push(item);
        });
      }
      const payload = {
        voucherList: newVoucherData,
        isCampaignActive,
        isLoader:true,
      };

      dispatch(voucherSlice.actions.setVoucherData(payload));
      //member details
      dispatch(memberDetailsSlice.actions.setMemberDetails(memberDetails));

    //transaction details
    const transactionsData = (transactionsAll.content as any[]).map(
        ({
          eventLineId,
          eventDateTime,
          tradingGroup,
          locationName,
          eventValue
        }) => {
          return {
            id: eventLineId,
            dateTime: eventDateTime,
            tradingGroup: tradingGroup,
            locationName: locationName,
            value: eventValue
          };
        }
      );
  
      const payload1 = {
        transactionsList: transactionsData,
        isLoader:transactionsData?false:true
      };
      dispatch(transactionsSlice.actions.setTransactionsData(payload1));
    }

    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch (err) {
    const { status, message } = err;
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default bulkSlice;