import React from 'react';

import classes from './Toast.module.css';

interface Toast {
  visible: boolean;
  message: string;
  type: 'ERROR' | 'SUCCESS';
  onClick?: Function;
}
/**
 * Note, this only retries GETs that have failed and been
 * added to uiSlice > inlineApiErrors.
 * This currently will display on every page,
 * i.e the page may not be related to the api call that failed
 */

const Toast: React.FC<Toast> = ({ visible, message, type, onClick }) => {
  return (
    <button
      className={visible ? classes.retryButton : classes.retryButtonHidden}
      style={type === 'SUCCESS' ? { background: '#8bc860' } : undefined}
      type="button"
      role="link"
      tabIndex={!visible ===false ? 0 : -1}
      aria-hidden={!visible}
      data-testid="retry-fetch"
      onClick={() => onClick && onClick()}
    >
      {message}
    </button>
  );
};

export default Toast;
