import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import Keycloak from 'keycloak-js';
import { Provider } from 'react-redux';
import { getCookie } from './utils';
import store from './appState/store';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { logoutUser, fetchSessionDetails } from './api';
import ManagePreferences from './screens/Manage/ManagePreferences';
import UnsubscribeContainer from './screens/UnsubscribeContainer/Unsubscribe';
const Error = require('./Error').default;

const isNative = getCookie('isNative') === 'true';

const BrowserRouter: React.FC<PropsWithChildren<{}>> = Router as unknown as React.FC<PropsWithChildren<{}>>;

const render = async () => {
  const pathName = window?.location?.pathname;
  const code: string | undefined = pathName.split('/').pop();

  const queryString = window?.location.search;
  const customerIdRegex = /customerId=([0-9]+)/;
  const match = queryString.match(customerIdRegex);
  let customerId: string | undefined;
  if (match) {
    customerId = match[1];
    // You can use the customerId here as needed
  } else {
    console.log('Customer ID not found in the string.');
  }
  // const getParams = () => {
  //   const str = window.location.search;
  //   const params: any = new URLSearchParams(str) || {};
  //   const obj: any = {};
  //   for (const [key, value] of params) {
  //     obj[key] = value;
  //   }
  //   return obj;
  // };
  // const obj = {}; //getParams();
  const config = {
    rules: [
      {
        id: 'skip-link',
        enabled: true
      }
    ],
    disableDeduplicate: true
  };

  if (pathName && (pathName === '/error' || pathName === '/error/')) {
    ReactDOM.render(
      <Error isNative={isNative} />,
      document.getElementById('root')
    );
  } else if (isNative) {
    //|| obj.isNative) {
    const token = getCookie('token'); // || obj.token;
    const nativeHeaderPadding = getCookie('nativeHeaderPadding'); // || obj.nativeHeaderPadding;
    if (!token) {
      throw new Error('No token cookie set!');
    }
    const App = require('./App').default;

    ReactDOM.render(
      <Provider store={store}>
        <App
          token={token}
          isNative={isNative}
          nativeHeaderPadding={nativeHeaderPadding}
        />
      </Provider>,
      document.getElementById('root')
    );
  } else if (pathName.indexOf('/manage/preferences') !== -1) {
    ReactDOM.render(
      <BrowserRouter>
        <ManagePreferences pathName={pathName} />
      </BrowserRouter>,
      document.getElementById('root')
    );
  } else if (
    pathName
      .trim()
      .toLowerCase()
      .includes('/unsubscribe/')
  ) {
    ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <UnsubscribeContainer code={code} customerId={customerId} pathName={pathName} />
        </BrowserRouter>
      </Provider>,
      document.getElementById('root')
    );
  } else if (process.env.REACT_APP_TARGET_ENV === 'local') {
    const App = require('./App').default;

    ReactDOM.render(
      <Provider store={store}>
        <App
          token={
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiTXIgVGVzdCBVc2VyIiwiY2FyZG51bWJlciI6IjYzMzE3NDk1MDIxMDY3ODEzNiIsIm1lbWJlcmlkIjoiODE1NzY0NSJ9.UJ-FWJxohE3o6KIbjkw6XJaVPDu45l-D3ry_8E4oL44'
          }
          isNative={isNative}
        />
      </Provider>,
      document.getElementById('root')
    );
  } else {
    const keycloak = Keycloak({
      realm: `${process.env.REACT_APP_KC_REALM}`,
      url: `${process.env.REACT_APP_KC_URL}/auth/`,
      clientId: `${process.env.REACT_APP_KC_CLIENT_ID}`
    });

    keycloak.onAuthSuccess = () => {
      //Event call
      // eventTrackingCall(keycloak.token);
    };

    const authenticated = await keycloak.init({
      checkLoginIframe: false
    });

    if (!authenticated) {
      await keycloak.login({
        idpHint: `${process.env.REACT_APP_KC_IDP}`
      });
    }

    if (authenticated) {
      if (window.location.pathname.indexOf('/logoutflow/') !== -1) {
        //@typescript-eslint/camelcase
        const data = {
          client_id: `${process.env.REACT_APP_KC_CLIENT_ID}`,
          refresh_token: keycloak.refreshToken,
          access_token: keycloak.token
        };
        await logoutUser(keycloak.token, data);
        window.location.replace(window.location.origin);
        return null;
      }

      let sessionData = null;
      try {
        sessionData = await fetchSessionDetails(keycloak.token as string);
      } catch (error) {
        console.log('=======error session', error);
        sessionData = null;
      }

      //If no session data(api fails), if errorMessage key is not there,
      // or if this key has value in it show error screen
      if (!sessionData || sessionData.errorMessage !== null) {
        ReactDOM.render(
          <Error isNative={isNative} message={sessionData?.errorMessage} />,
          document.getElementById('root')
        );
      } else {
        const App = require('./App').default;

        ReactDOM.render(
          <Provider store={store}>
            <App token={keycloak.token} isNative={isNative} />
          </Provider>,
          document.getElementById('root')
        );
      }
    }
  }
};

render();

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// @ts-ignore
if (process.env.NODE_ENV !== 'development' && !window.Cypress) {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
