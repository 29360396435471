import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import CryptoJS from 'crypto-js';

import { AppThunk } from './store';
import uiSlice from './uiSlice';
import * as api from '../api';
import jsondata from '../mock-data/unsubscribe.json';

export interface OptionItemInterface {
  optionId: string;
  label: string;
  value: string;
  selected: boolean;
}

export interface SummaryInterface {
  summaryId: string;
  label: string;
}

export interface SectionInterface {
  sectionId: string;
  options: OptionItemInterface[];
  summary: SummaryInterface;
  [key: string]: string | OptionItemInterface[] | SummaryInterface;
}

export interface SettingsInterface {
  id: string | null;
  customerId: string | null;
  preferenceSet?: boolean | null;
  sections: Array<SectionInterface>;
  apiPostError?: any;
}

const initialState: SettingsInterface = {
  customerId: '',
  id: '',
  preferenceSet: null,
  sections: [],
  apiPostError: null
};

const unsubscibeSlice = createSlice({
  name: 'unsubscibeSlice',
  initialState,
  reducers: {
    setUserSettingsData(state, action: PayloadAction<SettingsInterface>) {
      const { id, customerId, sections } = action.payload;

      state.id = id;
      state.customerId = customerId;
      state.sections = sections;
    },
    setApiPostError(state, action) {
      console.log('action:', action);
    },
    clearApiPostError(state) {
      state.apiPostError = null;
    }
  }
});

export const fetchUnsubscribePreferences = (props: any): AppThunk => async (
  dispatch,
  getState
) => {
  const apiKey = 'fetchUnsubscribePreferences';

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const customerId: any = props?.customerId;
    const memberId = props?.code;

    //console.log("khkhkdhsdkh",customerId.memberId, memberId);

    const unsubscribeData = await api.fetchUnsubscibeSettings(
      customerId,
      memberId
    );
    dispatch(unsubscibeSlice.actions.setUserSettingsData(unsubscribeData));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export const updateUnsubscibeSettings = (
  settingsData: Array<SectionInterface>,
  props: any
): AppThunk => async (dispatch, getState) => {
  const apiKey = 'updateUserPreferences';
  console.log(settingsData, props, 'utyutttyu');
  // Note: errors for this are handled within this slice unlike GET errors
  dispatch(unsubscibeSlice.actions.clearApiPostError());

  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { id } = getState().userSettingsSlice;
    const customerId: any = props?.customerId;
    const memberId = props?.code;
    const requestData: any = {
      id,
      customerId: customerId,
      memberId: memberId,
      sections: settingsData
    };

    const updateUnsubscribeData = await api.updateUnsubscibeSettings(
      requestData
    );
    if (updateUnsubscribeData.status == 200) {
      dispatch(
        unsubscibeSlice.actions.setUserSettingsData({
          ...requestData,
          preferenceSet: true
        })
      );
      dispatch(uiSlice.actions.removeApiLoading(apiKey));
    }
    // else{
    //   dispatch(
    //     uiSlice.actions.setApiError({ "404", message, apiKey, type: 'post' })
    //   );
    //   dispatch(uiSlice.actions.removeApiLoading(apiKey));
    // }
  } catch ({ status, message }) {
    dispatch(
      uiSlice.actions.setApiError({ status, message, apiKey, type: 'post' })
    );
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default unsubscibeSlice;
