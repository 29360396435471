import React, { useState, useEffect } from 'react';

import {
  fetchPublicCustomerPref,
  updatePublicCustomerPref,
  unSubscribePreferences
} from '../../api';
import { useLocation } from 'react-router-dom';
import InterestContainer from '../Interests/InterestContainer';
import classes from './Preference.module.css';
import produce from 'immer';
import { SettingsInterface } from '../../appState/userSettingsSlice';
import Toast from '../../components/Toast/Toast';
import Loading from '../../components/Loading/Loading';
import DialogSuccessBox from '../../components/DialogBox/DialogSuccessBox';

interface ToastConfig {
  visible: boolean;
  message: string;
  type: 'ERROR' | 'SUCCESS';
}
export interface OptionItemInterface {
  optionId: string;
  label: string;
  value: string;
  selected: boolean;
}
export interface SummaryInterface {
  summaryId: string;
  label: string;
}
export interface SectionInterface {
  sectionId: string;
  options: Array<OptionItemInterface>;
  summary: SummaryInterface;
}
interface PreferencesProps {
  pathName: string;
}

const ManagePreferences: React.FC<PreferencesProps> = ({ pathName }) => {
  const [sections, setSections] = useState<Array<SectionInterface>>([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [custId, setCustId] = useState<string | null>('');
  const [hashCustId, setHashCustId] = useState<string | null>('');
  const [settingSections, setSettingSections] = useState<SettingsInterface>({
    sections: [],
    id: '',
    customerId: ''
  });
  const [toastConfig, setToastConfig] = useState<ToastConfig>({
    visible: false,
    message: '',
    type: 'ERROR'
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [updatePref, setUpdatePref] = useState(false);

  useEffect(() => {
    if (!initialRender) {
      const [, , section3Cached] = sections;
      const [, , section3Local] = settingSections?.sections;

      if (section3Cached && section3Local) {
        const areEqual = section3Cached.options.every(
          (local, i) => local.selected === section3Local.options[i].selected
        );
        setShouldUpdate(!areEqual);
      }
    }
    setInitialRender(false);
  }, [initialRender, settingSections, sections]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const hideToast = () => {
    setToastConfig({
      visible: false,
      message: '',
      type: 'ERROR'
    });
  };

  const query = useQuery();

  const customerPref = async (datatopass: string) => {
    try {
      const result = await fetchPublicCustomerPref(datatopass);
      setSettingSections(result);
      setSections(result.sections);
      setIsLoading(false);
    } catch (err) {
      console.log('Error ', err);
      setIsLoading(false);
    }
  };

  //@ts-nocheck
  useEffect(() => {
    const custId = query.get('customerId');
    const id = pathName.split('/')[3];
    setCustId(custId);
    setHashCustId(id);
    setIsLoading(true);
    customerPref(`${id}?customerId=${custId}`);
  }, [pathName, query]);

  const handleUpdateOption = (id: string) => {
    const optionIndexToUpdate = sections[2].options.findIndex(
      ({ optionId }) => id === optionId
    );

    const nextState = produce(sections, draftState => {
      draftState[2].options[optionIndexToUpdate].selected = !draftState[2]
        .options[optionIndexToUpdate].selected;
    });

    setSections(nextState);
  };

  const handleUpdateOptions = (id: string, firstSelection: boolean) => {
    const optionIndexToUpdate = sections[1].options.findIndex(
      ({ optionId }) => id === optionId
    );

    const newState = produce(sections, draftState => {
      draftState[1].options[optionIndexToUpdate].selected = !draftState[1]
        .options[optionIndexToUpdate].selected;
    });

    if (firstSelection === true) {
      const nextState = produce(newState, draftState => {
        draftState[2].options.forEach(option => {
          option.selected = true;
        });
      }
      );
      setSections(nextState);
      updatePublicPrefCallback(nextState);
    } else {
      setSections(newState);
      updatePublicPrefCallback(newState);
    }
  };

  const updatePublicPrefCallback = async (
    nextState: Array<SectionInterface>
  ) => {
    setIsLoading(true);
    try {
      await updatePublicCustomerPref(
        `${hashCustId}?customerId=${custId}`,
        nextState,
        custId,
        settingSections.id
      );
      await customerPref(`${hashCustId}?customerId=${custId}`);

      setIsLoading(false);
      setTimeout(() => {
        setShowSuccessMessage(true);
      }, 300);

      // setShouldUpdate(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handlePreference = () => {
    updatePublicPrefCallback(sections);
  };

  const updateSubscription = async (type: 'subscribe' | 'unsubscribe') => {
    if (type === 'unsubscribe') {
      try {
        await unSubscribePreferences(
          `${hashCustId}?customerId=${custId}`,
          type
        );
        customerPref(`${hashCustId}?customerId=${custId}`);
        setToastConfig({
          visible: true,
          message:
            "You've successfully unsubscribed from all our marketing communications.",
          type: 'SUCCESS'
        });
        setTimeout(() => {
          hideToast();
        }, 5000);
      } catch (err) {
        console.log(err);
        setToastConfig({
          visible: true,
          message: 'Error, Try Again',
          type: 'ERROR'
        });
        setTimeout(() => {
          hideToast();
        }, 5000);
      }
    }
  };

  const handleFunctionFromChild = (funcFromChild: any) => {
    funcFromChild();
  };

  return (
    <div className={classes.userSettings}>
      {isLoading ? <Loading /> : null}
      <Toast {...toastConfig} onClick={() => hideToast()} />
      <DialogSuccessBox
        visible={showSuccessMessage}
        contentText={'Your preferences have been updated!'}
        successText={'Close'}
        successCallback={() => {
          setShowSuccessMessage(false);
        }}
      />
      <div className={classes.settingsContainer}>
        <InterestContainer
          sectionList={sections}
          handleUpdateOption={handleUpdateOption}
          handleUpdateOptions={handleUpdateOptions}
          shouldUpdate={!shouldUpdate}
          updateSubscription={updateSubscription}
          updatePreferences={handlePreference}
          showBackButton={false}
          toast={toastConfig}
          sendUpdateFunction={handleFunctionFromChild}
          shouldUpdateFromPopup={updatePref}
          updateSettingStateToInitialValue={() => setSections(settingSections?.sections)}
        />
      </div>
    </div>
  );
};

export default ManagePreferences;
