import React from 'react';
import classes from './InterestIcon.module.css';
import FoodAndDrinkIcon from './icons/food-and-drink.png';
import LeisureAndTravelIcon from './icons/leisure-and-travel.png';
import HealthAndWellbeingIcon from './icons/health-and-wellbeing.png';
import LifeWithYoungChildrenIcon from './icons/life-with-young-children.png';
import HomeAndGardenIcon from './icons/home-and-garden.png';
import GiftsAndTreatsIcon from './icons/gifts-and-treats.png';
import FinancialProductsIcon from './icons/financial-products.png';
import MemorialsAndFuneralsIcon from './icons/memorials-and-funerals.png';
import WeeklyOfferIcon from './icons/weeklyOffers.png';

interface InterestIconProps {
  iconName: string;
}

const InterestIcon: React.FC<InterestIconProps> = ({ iconName }) => {
  let iconUrl = null;
  switch (iconName) {
    case 'food-and-drink':
      iconUrl = FoodAndDrinkIcon;
      break;
    case 'leisure-breaks-and-holidays':
      iconUrl = LeisureAndTravelIcon;
      break;
    case 'health-and-wellbeing':
      iconUrl = HealthAndWellbeingIcon;
      break;
    case 'parent-and-child':
      iconUrl = LifeWithYoungChildrenIcon;
      break;
    case 'running-your-home':
      iconUrl = HomeAndGardenIcon;
      break;
    case 'gifts-and-treats':
      iconUrl = GiftsAndTreatsIcon;
      break;
    case 'financial-products':
      iconUrl = FinancialProductsIcon;
      break;
    case 'memorials-and-funerals':
      iconUrl = MemorialsAndFuneralsIcon;
      break;
    case 'weekly-offers':
      iconUrl = WeeklyOfferIcon;
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn('Incorrect icon name specified');
      return null;
  }

  return (
    <i aria-hidden className={classes.iconContainer}>
      <img alt={iconName} src={iconUrl} />
    </i>
  );
};

export default InterestIcon;
