import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import { ListItemInterface } from '../types';
import * as api from '../api';
import jsondata from '../mock-data/categories.json';
import { shuffle } from '../utils/ArrayUtil';
import communityDataMock from '../mock-data/community.json';

export interface CommunityOffersInterface {
  communityList: Array<ListItemInterface>;
  communityOffers: any;
  communityDataList: Array<ListItemInterface>;
}

const initialState = {
  communityList: [],
  communityOffers: {},
  communityDataList: []
};

const communitySlice = createSlice({
  name: 'communitySlice',
  initialState,
  reducers: {
    setCommunityData(state, action: PayloadAction<any>) {
      state.communityList = action.payload.communityList;
      state.communityOffers = { ...action.payload.communityOffers };
      state.communityDataList = action.payload.communityDataList;      
    }
  }
});

export const fetchCommunityOffers = (): AppThunk => async (
  dispatch,
  getState
) => {
  const apiKey = 'fetchCommunityOffers';

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const communityData = await api.fetchCommunityOffers(`${token}` as string);
    //const communityData = communityDataMock;

    const communityList = [] as any;
    const communityOffers = {} as any;
    const communityDataList = [] as any;    

    if (communityData?.length) {
    
      communityData.forEach((item: any) => { 
        
        const offerCategory = item?.offerCategory;
        const offerCategoryOrder = item?.categoryOrder; 
        
        const categoryId = offerCategory
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '');

        const communityItem = {} as any;
        communityItem['offerCategory'] = offerCategory;
        communityItem['offerCategoryOrder'] = offerCategoryOrder;
        communityItem['categoryId'] = categoryId;

        if (!communityList.find((item: any) => item.categoryId.includes(categoryId))) {
          if (communityItem.offerCategory) {
            communityList.push(communityItem);
          }
        }

        if (!communityOffers[categoryId]) {
          communityOffers[categoryId] = [];
          communityOffers[categoryId].push(item);
        } else {
          communityOffers[categoryId].push(item);
        }

        communityDataList.push(item);
      });
    }

    communityList.sort((a: any, b: any) => {
      if (a.offerCategoryOrder === undefined && b.offerCategoryOrder === undefined) {
        return 0;
      } else if (a.offerCategoryOrder === undefined) {
        return 1;
      } else if (b.offerCategoryOrder === undefined) {
        return -1;
      } else {
        const orderComparison = a.offerCategoryOrder - b.offerCategoryOrder;
        if (orderComparison === 0) {
          return Math.random() - 0.5;
        }
        return orderComparison;
      }
    });

    const getPriority = (offer: any) => offer.priority ?? Number.MAX_SAFE_INTEGER;

    for (const key in communityOffers) {
      if (communityOffers.hasOwnProperty(key)) {
        const sortedArray = communityOffers[key].sort((a: any, b: any) => {
          const aPriority = getPriority(a);
          const bPriority = getPriority(b);

          if (aPriority === bPriority) {
            return Math.random() - 0.5;
          }
          if (aPriority === Number.MAX_SAFE_INTEGER) {
            return 1;
          }
          if (bPriority === Number.MAX_SAFE_INTEGER) {
            return -1;
          }
          return aPriority - bPriority;
        });
        communityOffers[key] = [...sortedArray];
      }
    }

    dispatch(
      communitySlice.actions.setCommunityData({
        communityList,
        communityOffers,
        communityDataList
      })
    );
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default communitySlice;
