import React, { useState, useEffect, useRef } from 'react';
import {  useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from '../../components/BackButton/BackButton';
import { RootState } from '../../appState/rootReducer';
import { ListItemInterface } from '../../types';
import { UiInterface } from '../../appState/uiSlice';

import classes from './DetailView.module.css';
import ActionButton from '../ActionButton/ActionButton';
import DialogBox from '../DialogBox/DialogBox';
import { redeemReward } from '../../api';
import { AuthInterface } from '../../appState/authSlice';
import { fetchStampsData } from '../../appState/stampsSlice';
import { fetchRewards } from '../../appState/rewardsSlice';

import Infinity from '../../assets/images/Subtract.png';
interface Props {
  data: ListItemInterface;
  isNative: boolean;
  isClaimed?: boolean;
  isClaimable?: boolean;
  notEnoughStamps?: boolean;
  toggleLoader?: (value: boolean) => void;
  showToast?: (message: string) => void;
  isFullWidthHeader?: boolean;
}

const DetailView: React.FC<Props> = ({
  data,
  isNative,
  isClaimable,
  isClaimed,
  notEnoughStamps,
  toggleLoader,
  showToast,
  isFullWidthHeader = false
}) => {
  const { view } = useSelector<RootState, UiInterface>(
    ({ uiSlice }) => uiSlice
  );
  const dispatch = useDispatch();
  const { token } = useSelector<RootState, AuthInterface>(
    ({ authSlice }) => authSlice
  );
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const onBlur = () => {
    setDialogVisible(false);
  };

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, []);

  const {
    id,
    imgUrlSmall,
    altText,
    heading1,
    heading2,
    description2,
    heading3,
    description3,
    heading4,
    description4,
    heading5,
    description5,
    heading6,
    description6,
    redemptionUrl,
    isDirectLink,
    redemptionButtonCaption,
    redemptionButtonCaptionColour,
    redemptionButtonColour,
    endDate,
    offerBonusText,
    wasPrice,
    offerPrice
  } = data;

  const discountPrices =
    !Boolean(offerPrice) || offerPrice !== '0' ? offerPrice?.toString() : '';
  const currentPrices =
    !Boolean(wasPrice) || wasPrice !== '0' ? wasPrice?.toString() : '';

  function func(variable: any) {
    return (
      variable !== undefined &&
      variable !== null &&
      !(typeof variable === 'string' && variable.trim() === '')
    );
  }

  let chklist = false;
  if (
    func(offerBonusText) ||
    func(heading2) ||
    func(discountPrices) ||
    func(currentPrices) ||
    func(heading3) ||
    func(description3) ||
    func(description2)
  ) {
    chklist = true;
  }

  return (
    (
      <div className={classes.detailContent}>
        <DialogBox
          visible={dialogVisible}
          headerText={
            'Are you sure you want to claim this reward?  Once claimed you’ll need to collect your reward before'
          }
          contentText={endDate}
          successText={'Claim Reward'}
          cancelText={'Cancel'}
          successCallback={async () => {
            toggleLoader && toggleLoader(true);
            const res = await redeemReward(token as string, id);
            if (!res) {
              dispatch(fetchStampsData());
              dispatch(fetchRewards());
            }
            toggleLoader && toggleLoader(false);
            setDialogVisible(false);
            if (res) {
              showToast && showToast(res);
            }
          }}
          cancelCallback={() => {
            setDialogVisible(false);
          }}
        />

        <div
          style={{
            position: 'sticky',
            top: '-1px',
            backgroundColor: '#ffffff'
          }}
        >
          {' '}
          <BackButton />
        </div>

        <div role={'img'} tabIndex={0} aria-label={heading1}>
          <img
            aria-label="img"
            src={imgUrlSmall}
            alt="images"
            className={classes.heroImage}
          />
        </div>
        {isClaimed ? (
          <section
            className={classes.claimedInfoHeader}
            role="article"
            aria-label={`You claimed this reward. Redeem it before end date: ${endDate}`}
          >
            <div className={classes.leftContainer}>
              <h5>{'You claimed this reward. Redeem it before end date:'}</h5>
              <h2>{endDate}</h2>
            </div>
            <h5
              className={classes.rightContainer}
              aria-label="You’ll receive an email once campaign is active."
            >
              {'You’ll receive an email once campaign is active.'}
            </h5>
          </section>
        ) : null}
        {(!isFullWidthHeader && (
          <div
            role="article"
            aria-label={`${heading1}`}
            tabIndex={!isFullWidthHeader ? 0 : -1}
          >
            <h1 className={classes.infoHeading}>{heading1}</h1>
            {//  checkPost
            chklist ? (
              <section className={classes.infoHeader}>
                <div>
                  {offerBonusText != null ||
                    ('' && (
                      <button className={classes.roundedBtn}>
                        <span className={classes.roundedIcon}>
                          <img alt="" src={Infinity} />
                        </span>
                        <span className={classes.roundedTxt}>
                          {data.offerBonusText}
                        </span>
                      </button>
                    ))}
                  <div
                    className={classes.infoCells}
                    role="article"
                    aria-label={`${heading2} ${description2} ${heading3} ${description3}`}
                  >
                    {/* <div>
                      <h5>{heading2}</h5>
                      <div className={classes.flexLeftRightPrice}>
                        <h2 className={classes.discountPrice}>
                          {discountPrices ? `£${discountPrices}` : ''}
                        </h2>
                        <h3 className={classes.currentPrice}>
                          {currentPrices ? `£${currentPrices}` : ''}
                        </h3>
                      </div>
                    </div> */}
                    <div>
                      <h5>{heading2}</h5>
                      <p>{description2}</p>
                    </div>
                    <div style={{ paddingLeft: '10px' }}>
                      <h5>{heading3}</h5>
                      <p>{description3}</p>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
          </div>
        )) || (
          <section
            aria-label={heading1}
            role={'article'}
            className={classes.infoHeaderFullWidth}
            tabIndex={0}
          >
            <h1> {heading1}</h1>
          </section>
        )}
        {(!isFullWidthHeader && (
          <div className={classes.desktopRow}>
            <section
              className={classes.cell}
              aria-label={`${heading4} ${description4} ${heading5 &&
                description5 &&
                heading5 + description5}`}
              tabIndex={!isFullWidthHeader ? 0 : -1}
              role={'article'}
            >
              <div>
                <h2>{heading4}</h2>
                <p>{description4}</p>
              </div>
              {heading5 && description5 ? (
                <div className={classes.topPadding}>
                  <h2>{heading5}</h2>
                  <p>{description5}</p>
                </div>
              ) : null}
              <section
                className={classes.topPadding}
                role={'article'}
                aria-label={`${heading6} ${description6}`}
                tabIndex={!isFullWidthHeader ? 0 : -1}
              >
                <h2>{heading6}</h2>
                <p>{description6}</p>
              </section>
              <div
                className={
                  isNative ? classes.ctaContainerNative : classes.ctaContainer
                }
                data-testid={'cta-button'}
              >
                {redemptionUrl  && (
                    <div className={isNative ? classes.ctaNative : classes.cta}>
                      <ActionButton
                        text={
                          notEnoughStamps
                            ? 'Unlock with more stamps'
                            : redemptionButtonCaption
                            ? redemptionButtonCaption
                            : 'More Info'
                        }
                        href={isClaimable ? undefined : redemptionUrl}
                        style={
                          notEnoughStamps
                            ? {
                                backgroundColor: '#b2b2b2',
                                color: '#FFFFFF'
                              }
                            : {
                                backgroundColor: redemptionButtonColour,
                                color: redemptionButtonCaptionColour
                              }
                        }
                        disabled={notEnoughStamps}
                        noBorder={notEnoughStamps}
                        external={!isClaimable}
                        onClick={() => {
                          if (isClaimable && !notEnoughStamps) {
                            setDialogVisible(true);
                          }
                        }}
                      />
                    </div>
                  )}
              </div>
            </section>
          </div>
        )) || (
          // get involved tab
          <div
            className={classes.sections}
            role={'article'}
            aria-label={`${heading4} ${description4}`}
            tabIndex={0}
          >
            <section
              className={classes.cellRow}
              aria-label={`${heading4} ${description4}`}
              role={'article'}
              tabIndex={0}
            >
              <h2>{heading4}</h2>
              <p>{description4}</p>
            </section>
            <section
              className={classes.cellRow}
              aria-label={`${heading2} ${description2}`}
              role={'article'}
              tabIndex={0}
            >
              <h2>{heading2}</h2>
              <p>{description2}</p>
              <div
                className={
                  isNative ? classes.ctaContainerNative : classes.ctaContainer
                }
                data-testid={'cta-button'}
              >
                {/* {redemptionUrl != '' || redemptionUrl == null && 
                <div className={isNative ? classes.ctaNative : classes.cta}>
                  <ActionButton
                    text={
                      notEnoughStamps
                        ? 'Unlock with more stamps'
                        : redemptionButtonCaption
                        ? redemptionButtonCaption
                        : 'More Info'
                    }
                    href={redemptionUrl}
                    style={
                      notEnoughStamps
                        ? {
                            backgroundColor: '#b2b2b2',
                            color: '#FFFFFF'
                          }
                        : {
                            backgroundColor: redemptionButtonColour,
                            color: redemptionButtonCaptionColour
                          }
                    }
                    disabled={notEnoughStamps}
                    noBorder={notEnoughStamps}
                    external={true}
                  />
                </div>
                } */}
              </div>
              {/* )} */}
            </section>
          </div>
        )}
        {redemptionButtonCaption && !isClaimed && isNative ? (
          <div className={classes.extraPadding} />
        ) : null}
      </div>
    )
  );
};

export default DetailView;
