import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import * as api from '../api';
import moment from 'moment';
import voucherDataMock from '../mock-data/vouchers.json';

export interface VoucherItemInterface {
  id: string;
  barcode: string;
  barcodeAlpha: string;
  expiryDate: string;
  redeemedDate: string;
  status: string;
  redeemed: boolean;
  tradingGroup: string;
  voucherType: string;
  voucherValue: number;
  showBarcode: boolean;
  created: any;
}

export interface VoucherInterface {
  voucherList: Array<VoucherItemInterface>;
  isCampaignActive: boolean;
  isLoader: boolean;
}

const initialState: VoucherInterface = {
  voucherList: [],
  isCampaignActive: false,
  isLoader:true,
};

const voucherSlice = createSlice({
  name: 'voucherSlice',
  initialState,
  reducers: {
    setVoucherData(state, action: PayloadAction<VoucherInterface>) {
      state.voucherList = action.payload.voucherList;
      state.isCampaignActive = action.payload.isCampaignActive;
      state.isLoader = action.payload.isLoader;
    }
  }
});

export const fetchVoucher = (): AppThunk => async (dispatch, getState) => {
  const apiKey = 'fetchVoucher';
  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const voucherData = await api.fetchVoucher(token as string); 
    //const voucherData = voucherDataMock;
    const newVoucherData = [] as any;
    const isCampaignActive = !!voucherData;
    if (voucherData?.length) {
      let id = 1000;
      voucherData.forEach((item: any) => {
        id++;
        item['id'] = id.toString();
        item['redeemed'] = item?.status === 'redeemed';
        item['expiryDate'] = item?.expiryDate
          ? moment(item.expiryDate).format('DD/MM/YYYY')
          : null;
        item['redeemedDate'] = item?.redeemedDate
          ? moment(item.redeemedDate).format('DD/MM/YYYY')
          : null;

        newVoucherData.push(item);
      });
    }
    const payload = {
      voucherList: newVoucherData,
      isCampaignActive,
      isLoader:newVoucherData?false:true
    };

    dispatch(voucherSlice.actions.setVoucherData(payload));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default voucherSlice;
