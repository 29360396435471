import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './authSlice';
import stampsSlice from './stampsSlice';
import offersSlice from './offersSlice';
import userSettings from './userSettingsSlice';
import rewardsSlice from './rewardsSlice';
import memberBenefitsSlice from './memberBenefitsSlice';
import transactionsSlice from './transactionsSlice';
import progressionPointSlice from './progressionPointSlice';
import uiSlice from './uiSlice';
import memberDetailsSlice from './memberDetailsSlice';
import voucherSlice from './voucherSlice';
import bulkSlice from './bulkSlice';
import communitySlice from './communitySlice';
import charitySlice from './charitySlice';
import memberSavingSlice from './MemberSavingsSlice';
import memberGUIDSlice from './memberGUIDSlice';
import unsubscibeSlice from './unsubscribeSlice';
const rootReducer = combineReducers({
  authSlice: authSlice.reducer,
  memberDetailsSlice: memberDetailsSlice.reducer,
  memberGUIDSlice: memberGUIDSlice.reducer,
  stampsSlice: stampsSlice.reducer,
  memberSavingSlice: memberSavingSlice.reducer,
  offersSlice: offersSlice.reducer,
  communitySlice: communitySlice.reducer,
  charitySlice: charitySlice.reducer,
  userSettingsSlice: userSettings.reducer,
  unsubscibeSlice: unsubscibeSlice.reducer,
  rewardsSlice: rewardsSlice.reducer,
  voucherSlice: voucherSlice.reducer,
  memberBenefitsSlice: memberBenefitsSlice.reducer,
  transactionsSlice: transactionsSlice.reducer,
  progressionPointSlice: progressionPointSlice.reducer,
  uiSlice: uiSlice.reducer,
  bulkSlice: bulkSlice.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
