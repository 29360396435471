export const CAROUSEL_NUMBER = 10;

export const getCookie = (name: string) => {
  const value = '; ' + document.cookie;
  const parts = value.split('; ' + name + '=');

  if (parts.length === 2) {
    // @ts-ignore
    return parts
      .pop()
      .split(';')
      .shift();
  }
};

export const fetchHandler = (url: string, params: RequestInit) => {
  return fetch(url, params).then((response: any) => {
    if (response.ok) {
      return response.json();
    }

    throw response;
  });
};

export const ERRORS = {
  UNAUTHORIZED: {
    //HEADING: 'Uh oh!',
    HEADING: 'Something went wrong',
    //WEB_MSG1: 'Refresh the page to try again.',
    WEB_MSG1: 'Close the app and open again',
    MOBILE_MSG1: 'Pull down to refresh.',
    MSG2: 'If the error persists, please log back in to continue.'
  }
};

export const BANNER_TEXT = {
  HOME: {
    HEADING1: 'Membership',
    HEADING2: 'makes the difference',
    DESCRIPTION: 'For you, your community and your world'
  },
  VALUE: {
    HEADING1: 'Special offers',
    HEADING2: 'just for members',
    DESCRIPTION: 'What’s hot instore right now'
  },
  YOURCOOP: {
    HEADING1: 'How Your Co-op Membership',
    HEADING2: 'makes the difference',
    DESCRIPTION: 'For you, your community and your world'
  },
  SETTING: {
    HEADING1: 'Settings & Preferences',
    HEADING2: '',
    DESCRIPTION: ''
  }
};

export function isFloat(n: any) {
  return Number(n) === n && n % 1 !== 0;
}

export function removeEUCookies() {
  const oneTrustScript: any = document.getElementById('onetrust-eu-cookies');
  oneTrustScript?.remove();
}
