import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import { ListItemInterface } from '../types';
import * as api from '../api';
import jsondata from '../mock-data/categories.json';
import { shuffle } from '../utils/ArrayUtil';
import charityDataMock from '../mock-data/charity.json';
// import charityDataMock from '../mock-data/charityGeneral.json';

export interface CharityOffersInterface {
  charityList: Array<ListItemInterface>;
  charityOffers: any;
}

const initialState = {
  charityList: [],
  charityOffers: {}
};

const charitySlice = createSlice({
  name: 'charitySlice',
  initialState,
  reducers: {
    setcharityData(state, action: PayloadAction<any>) {
      state.charityList = action.payload.charityList;
      state.charityOffers = { ...action.payload.charityOffers };
    }
  }
});

export const fetchCharityOffers = (): AppThunk => async (
  dispatch,
  getState
) => {
  const apiKey = 'fetchCharityOffers';

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const charityData = await api.fetchCharityOffers(`${token}` as string);
    //const charityData = charityDataMock;

    const charityList = [] as any;
    const charityOffers = {} as any;

    if (charityData?.length) {
      charityData.forEach((item: any) => {
        // item['isClaimed'] = item?.redemptionStatus === 'claimed';
        // item['redeemed'] = item?.redemptionStatus === 'redeemed';
        // item['redeemed'] = item?.redemptionStatus === 'active';

        const region = item?.region;
        // const charityCategoryOrder = item?.offerCategoryOrder;
        const categoryId = region
          ?.toLowerCase()
          .trim()
          .replace(/\s/g, '');

        const charityItem = {} as any;
        charityItem['offerCategory'] = region;
        // charityItem['charityCategoryOrder'] = charityCategoryOrder;
        charityItem['categoryId'] = categoryId;

        if (!charityList.find((item: any) => item.categoryId.includes(categoryId))) {
          if (charityItem.offerCategory) {
            charityList.push(charityItem);
          }
        }

        if (!charityOffers[categoryId]) {
          charityOffers[categoryId] = [];
          charityOffers[categoryId].push(item);
        } else {
          charityOffers[categoryId].push(item);
        }
      });
    }

    dispatch(
      charitySlice.actions.setcharityData({
        charityList,
        charityOffers
      })
    );
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default charitySlice;
