// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DE8qgn-EORcgQWv7fWqEgg\\=\\= {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 100vh;\n  width: 100vw;\n}\n\n/* .blockingError .heading22{\n  padding: 0px 34px 17px 17px;\n} */\n\n/* .blockingError .heading11{\n  width: 100%;\n  padding: 0px 34px 0px 17px;\n  font-weight: bold;\n} */\n\n._50behHIBkpysbpK7P6K4lQ\\=\\= {\n  display: block;\n}\n\n/* .blockingError ol {\n  width: 100%;\n  padding: 0px 0px 0px 44px;\n  font-size: 14px;\n} */\n\n/* .blockingError ol li{\n  padding: 7px;\n} */\n\n.DE8qgn-EORcgQWv7fWqEgg\\=\\= p {\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://src/components/BlockingError/BlockingError.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,sBAAsB;EACtB,aAAa;EACb,YAAY;AACd;;AAEA;;GAEG;;AAEH;;;;GAIG;;AAEH;EACE,cAAc;AAChB;;AAEA;;;;GAIG;;AAEH;;GAEG;;AAEH;EACE,kBAAkB;AACpB","sourcesContent":[".blockingError {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  height: 100vh;\n  width: 100vw;\n}\n\n/* .blockingError .heading22{\n  padding: 0px 34px 17px 17px;\n} */\n\n/* .blockingError .heading11{\n  width: 100%;\n  padding: 0px 34px 0px 17px;\n  font-weight: bold;\n} */\n\n.heading {\n  display: block;\n}\n\n/* .blockingError ol {\n  width: 100%;\n  padding: 0px 0px 0px 44px;\n  font-size: 14px;\n} */\n\n/* .blockingError ol li{\n  padding: 7px;\n} */\n\n.blockingError p {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockingError": "DE8qgn-EORcgQWv7fWqEgg==",
	"heading": "_50behHIBkpysbpK7P6K4lQ=="
};
export default ___CSS_LOADER_EXPORT___;
