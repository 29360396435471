//@ts-nocheck
import React, { useMemo, lazy, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { MemberBenefitsInterface } from '../../appState/memberBenefitsSlice';
import ListView from '../../components/ListView/ListView';
import DetailView from '../../components/DetailView/DetailView';
import ParentNavChildNav from '../../components/ParentNavChildNav/ParentNavChildNav';
import Banner from '../../components/Banner/Banner';

import { UiInterface } from '../../appState/uiSlice';
import { RootState } from '../../appState/rootReducer';

import classes from './MemberBenefits.module.css';
// import HeaderImageBenefits from '../../assets/images/heading-benefits.png';
import { HeadingValuePage } from '../../assets/images';
import { BANNER_TEXT, removeEUCookies } from '../../utils';
const Footer = lazy(() => import('../../components/Footer/Footer'));

interface MemberBenefitsProps {
  isNative: boolean;
}

const MemberBenefits: React.FC<MemberBenefitsProps> = ({ isNative }) => {
  const { pathname } = useLocation();
  const { id: memberBenefitsItemId } = useParams();
  const { memberBenefitsList } = useSelector<
    RootState,
    MemberBenefitsInterface
  >(({ memberBenefitsSlice }) => memberBenefitsSlice);

  const { view, isStampsRewards } = useSelector<RootState, UiInterface>(
    ({ uiSlice }) => uiSlice
  );

  // eslint-disable-next-line 
  const handleEUCookies = () => {
    if (isNative) {
      removeEUCookies();
    }
  };
  useEffect(() => {
    handleEUCookies();
    // eslint-disable-next-line 
  }, []);

  useEffect(() => {
    handleEUCookies();
  }, [pathname, memberBenefitsList, handleEUCookies]);

  const currentMemberBenefitsItem = useMemo(() => {
    return memberBenefitsList.find(({ id }) => id === memberBenefitsItemId);
  }, [memberBenefitsItemId, memberBenefitsList]);

  const renderSubNav = () => {
    return (
      <ParentNavChildNav
        parentLinks={[
          {
            path: '/offers/',
            text: 'Your offers',
            childHeading: 'Offer details',
            selected: false
          },
          {
            path: '/offers/member-benefits/',
            text: 'Member benefits',
            childHeading: 'Benefit details',
            selected: true
          }
        ]}
        currentPage={pathname}
      />
    );
  };

  const isDetailView = !!currentMemberBenefitsItem;
  const showBanner = view !== 'mobile' && !isDetailView;
  const showSubNav = view === 'mobile' || !isDetailView;

  const divRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (typeof divRef?.current?.scrollTo === 'function')
      divRef.current.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={classes.memberBenefits} ref={divRef}>
      {showBanner && !memberBenefitsItemId ? (
        <Banner
          isStampsRewards={isStampsRewards}
          heading={BANNER_TEXT.VALUE.HEADING1}
          heading2={BANNER_TEXT.VALUE.HEADING2}
          text={BANNER_TEXT.VALUE.DESCRIPTION}
          imgSrc={HeadingValuePage}
          backgroundColor="#ffe8b4"
        />
      ) : null}
      <div className={classes.section}>
        {showSubNav ? renderSubNav() : null}
        {memberBenefitsItemId ? (
          currentMemberBenefitsItem ? (
            <DetailView data={currentMemberBenefitsItem} isNative={isNative} />
          ) : null
        ) : (
          <ListView
            data={memberBenefitsList}
            basePath="/offers/member-benefits/"
          />
        )}
      </div>
      {!isNative ? <Footer /> : null}
    </div>
  );
};

export default MemberBenefits;
