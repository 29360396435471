import React from 'react';

export default class LogoutFlow extends React.Component {
  componentDidMount = async () => {
    window.location.href = `${process.env.REACT_APP_KC_URL}/auth/realms/${process.env.REACT_APP_KC_REALM}/protocol/openid-connect/logout?redirect_uri=${window.location.origin}/logoutflow/`;
    /*const keycloak = Keycloak({
      realm: `${process.env.REACT_APP_KC_REALM}`,
      url: `${process.env.REACT_APP_KC_URL}/auth/`,
      clientId: `${process.env.REACT_APP_KC_CLIENT_ID}`
    });
    const data = {
      client_id: `${process.env.REACT_APP_KC_CLIENT_ID}`,
      refresh_token: keycloak.refreshToken,
      access_token: keycloak.token
    };
    await logoutUser(keycloak.token, data);
    window.location.href = `${process.env.REACT_APP_KC_URL}/auth/realms/${process.env.REACT_APP_KC_REALM}/protocol/openid-connect/logout?redirect_uri=${window.location.origin}/logoutflow/`;*/
  };

  render() {
    return null;
  }
}
