//@ts-nocheck

import React, { lazy, Suspense } from 'react';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Loading from './components/Loading/Loading';
import './App.css';

import classes from './Error.module.css';
import { CoopWhite } from './assets/images';

const Footer = lazy(() => import('./components/Footer/Footer'));

interface ErrorPropsInterface {
  isNative: boolean;
  message?: string;
}

const Error: React.FC<ErrorPropsInterface> = ({ isNative, message }) => {
  return (
    <div className={`app ${classes.errorContainer}`}>
      <ErrorBoundary>
        <Suspense fallback={<Loading />}>
          <div className={classes.headerError}>
            <CoopWhite />
          </div>
          <div className={classes.contentError}>
            <div className={classes.contentMainError}>
              <h1 className={classes.contentH1}>
                {`We’re having trouble logging you in`}
              </h1>

              <p className={classes.cotentParagraph}>
                {message ||
                  `Sorry, something went wrong with our system and we couldn’t log you in.  We are working on it.  Please try again later.`}
              </p>
            </div>
          </div>
          {!isNative ? <Footer /> : null}
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};

export default Error;
