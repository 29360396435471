import React from 'react';
import { Link } from 'react-router-dom';
import classes from './ActionButton.module.css';

interface StyleProps {
  backgroundColor: string;
  color: string;
}
interface ActionButtonProps {
  text: string;
  disabled?: boolean;
  onClick?: () => void;
  href?: string;
  external?: boolean;
  style?: StyleProps;
  noBorder?: boolean;
  lightBackground?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  text,
  onClick = () => {},
  disabled,
  href,
  external,
  style,
  noBorder,
  lightBackground
}) => {
  if (href && external) {
    return (
      <a href={href} className={classes.actionLink} style={style} target="_blank"  rel="noreferrer">
        {text}
      </a>
    );
  }

  if (href) {
    return (
      <Link
        to={href}
        className={classes.actionLink}
        onClick={onClick}
        style={style}   
      >
        {text}
      </Link>
    );
  }

  return (
    <button
      className={`${classes.actionButton} ${
        noBorder ? classes.noBroder : null
      } ${lightBackground ? classes.lightBackground : null}
      `}
      type="button"
      onClick={onClick}
      disabled={disabled}
      style={style}
    >
      {text}
    </button>
  );
};

export default ActionButton;
