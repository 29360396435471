import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt from 'jsonwebtoken';

export interface AuthInterface {
  token: string | null;
  memberName: string | null;
  cardNumber: string;
  memberId: string | null;
}

const initialState: AuthInterface = {
  token: null,
  memberName: null,
  cardNumber: '',
  memberId: null
};

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string>) {
      const token = action.payload;

      const decoded = jwt.decode(token);

      if (!decoded) {
        throw new Error('Invalid token');
      }

      // @ts-ignore
      const { name, memberid, cardnumber } = decoded;

      state.token = action.payload;
      state.memberName = name;
      state.cardNumber = cardnumber;
      state.memberId = memberid;
      document.cookie = 'memberid=' + memberid;
    }
  }
});
export default authSlice;
