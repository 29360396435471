import React, { CSSProperties } from 'react';
import classes from './Checkbox.module.css';

interface CheckboxProps {
  id: string;
  text: string;
  value: boolean;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  alignLeft?: boolean;
  inputStyle?: CSSProperties;
  headerTextStyle?: CSSProperties;
  mainConatinerStyles?: CSSProperties;
  disabled?: any;
}

const Checkbox: React.FC<CheckboxProps> = ({
  text,
  id,
  value,
  onChange,
  alignLeft = false,
  inputStyle,
  headerTextStyle,
  mainConatinerStyles,
  disabled = false
}) => {
  return (
    <div >
      <label
        // className={alignLeft ? classes.checkboxLeftAlign : classes.checkbox}
        className={alignLeft ? classes.checkboxLeftAlign : classes.checkbox}
        htmlFor={id}
        // htmlFor="Checkbox"
        data-testid="checkbox"
        data-cy="checkbox"
        style={mainConatinerStyles}
        aria-label={text}
      >
        <span style={headerTextStyle}>
          {text}
        </span>
        <input
          className={classes.input}
          type="checkbox"
          id={id}
          aria-checked={value}
          // id="checkbox"
          onChange={onChange}
          checked={value}
          style={inputStyle}
          disabled={disabled}
          aria-label={text}
        />
        <span  className={classes.fakeInput} />
      </label>
    </div>
  );
};

export default Checkbox;
