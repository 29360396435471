import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import * as api from '../api';
import progressionPointMockData from '../mock-data/progression.json';

export interface ProgressionItemInterface {
  currentPoints: number;
  description: string;
  homePageCategoryOrder: number;
  status: string;
  target: string;
  title: string;
}
export interface ProgressionInterface {
    progressionsList: Array<ProgressionItemInterface>;
}

const initialState: ProgressionInterface = {
    progressionsList: []
};

const progressionPointSlice = createSlice({
  name: 'progressionPointSlice',
  initialState,
  reducers: {
    setProgressionsData(state, action: PayloadAction<ProgressionItemInterface[]>) {
      state.progressionsList = action.payload;
    }
  }
});

export const fetchProgressionPoint = (): AppThunk => async (dispatch, getState) => {
  const apiKey = 'fetchProgressionPoint';
  // Todo: handle loading states...

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const response = await api.fetchProgressionPoint(token as string);
    //const response = progressionPointMockData;
    
    const progressionsData = (response as any[]).map(
      ({
        currentPoints,
        description,
        homePageCategoryOrder,
        status,
        target,
        title
      }) => {
        return {
          currentPoints: currentPoints,
          description: description,
          homePageCategoryOrder: homePageCategoryOrder,
          status: status,
          target: target,
          title: title
        };
      }
    ); 
    
    dispatch(progressionPointSlice.actions.setProgressionsData(progressionsData));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default progressionPointSlice;
