import React from 'react';
import classes from './ConfirmBox.module.css';
import PromptSign from '../../assets/images/promptWarning.png'

interface Props {
  visible?: boolean | undefined;
  successCallback(): void;
  cancelCallback(): void;
  cancelText: string;
  successText: string;
}

const ConfirmBox = ({
  visible,
  cancelText,
  successText,
  successCallback,
  cancelCallback
}: Props) => {
  return (
    <div>
      {visible ? (
        <div className={classes.confirmBox}>
          <div className={classes.promptContainer}>
            <img src={PromptSign} alt="Warning sign" />
            <h3>Don&apos;t forget to confirm your changes</h3>
          </div>
          <div className={classes.buttonContainer}>
          <button className={classes.cancelButton} onClick={cancelCallback}>
            {cancelText}
          </button>
          <button className={classes.confirmButton} onClick={successCallback}>
            {successText}
          </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default ConfirmBox;
