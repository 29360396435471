//@ts-nocheck
import React, {
  Suspense,
  lazy,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ResizeObserver } from 'resize-observer';
import NavigationMobile from './components/NavigationMobile/NavigationMobile';
import NavigationDesktop from './components/NavigationDesktop/NavigationDesktop';
import BlockingError from './components/BlockingError/BlockingError';
import Loading from './components/Loading/Loading';
import ActionButton from './components/ActionButton/ActionButton';
import RetryFetch from './components/RetryFetch/RetryFetch';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import MemberBenefits from './screens/MemberBenefits/MemberBenefits';
import { fetchMemberDetails } from './appState/memberDetailsSlice';
import { fetchMemberGUID } from './appState/memberGUIDSlice';
import { fetchUnsubscribePreferences } from './appState/userSettingsSlice';
import { fetchStampsData } from './appState/stampsSlice';
import { fetchOffers } from './appState/offersSlice';
import { fetchCommunityOffers } from './appState/communitySlice';
import { fetchCharityOffers } from './appState/charitySlice';
import { fetchRewards } from './appState/rewardsSlice';
import { fetchMemberBenefits } from './appState/memberBenefitsSlice';
import { fetchBulkData } from './appState/bulkSlice';
import { fetchTransactions } from './appState/transactionsSlice';
import { fetchProgressionPoint } from './appState/progressionPointSlice';
import { fetchMemberSavings } from './appState/MemberSavingsSlice';
import { RootState } from './appState/rootReducer';
import { navigation } from './configs';
import uiSlice, { UiInterface } from './appState/uiSlice';
import { StampsInterface } from './appState/stampsSlice';
import authSlice from './appState/authSlice';

import './App.css';
import TransactionPage from './screens/TransactionPage/Transactions';
import VoucherHome from './screens/VoucherPage/VoucherHome';

import UnsubscribeContainer from './screens/UnsubscribeContainer/Unsubscribe';

import LogoutFlow from './screens/LogoutFlow/LogoutFlow';
import Rewards from './screens/Rewards/Rewards';
import CookiePolicy from './screens/cookie-policy/cookie-policy';
import { fetchVoucher } from './appState/voucherSlice';
import ReactPixel from 'react-facebook-pixel';
import Vouchers from './screens/VoucherPage/Vouchers';
import MemberSavings from './screens/MemberSavingsPage/MemberSavings';
// import { getCookie } from './utils';

const MIN_DESKTOP_WIDTH = 1024;
const MIN_TABLET_WIDTH = 768;

const SwaggerUI = lazy(() => import('./screens/SwaggerUi/SwaggerUi'));

interface AppPropsInterface {
  token: string;
  isNative: boolean;
  nativeHeaderPadding: string;
}

const App: React.FC<AppPropsInterface> = ({
  token,
  isNative,
  nativeHeaderPadding = 0
}) => {
  const appContainerRef = useRef(null);
  const dispatch = useDispatch();
  const { apiAuthSuccess } = useSelector<RootState, StampsInterface>(
    ({ stampsSlice }) => stampsSlice
  );
  const { blockingApiError, view, isStampsRewards } = useSelector<
    RootState,
    UiInterface
  >(({ uiSlice }) => uiSlice);

  const deleteMatomoScript = () => {
    const matomoScript: any = document.getElementById(
      'matomo-analytics-script'
    );
    matomoScript?.remove();
  };

  const deleteMatomoTagManager = () => {
    const win: any = window;
    delete win['MatomoTagManager'];
    delete win['Matomo'];
  };

  const deleteMatomoCookies = () => {
    const cookies: any = document.cookie;
    const cookiesToDelete = cookies
      .split(';')
      .filter((cookie: any) => cookie.trim().startsWith('_pk'))
      .map((cookie: any) => cookie + ';samesite;max-age=-1');

    cookiesToDelete.forEach((cookie: any) => {
      document.cookie = cookie;
    });
  };
  const clearMatoma = () => {
    console.log('*** Deleteing matoma ***');
    deleteMatomoScript();
    deleteMatomoTagManager();
    deleteMatomoCookies();
  };

  const activateFbPixel = () => {
    ReactPixel.init('415993812543873');
    ReactPixel.track('PageView');
    ReactPixel.pageView();
    ReactPixel.grantConsent();
  };
  const handlePixcel = (activeGroups: any) => {
    if (!activeGroups.includes('C0005')) {
      ReactPixel.revokeConsent();
    } else if (!activeGroups.includes('C0003')) {
      clearMatoma();
    } else {
      activateFbPixel();
    }
  };
  function handleOneTrustGroupsChange(e: any) {
    try {
      const win: any = window;
      const activeGroups = win['OptanonActiveGroups'];
      handlePixcel(activeGroups);
    } catch (err) {
      console.error('Error occured in OT script manager', err);
    }
  }

  const handleOnetrust = () => {
    window.addEventListener(
      'OneTrustGroupsUpdated',
      handleOneTrustGroupsChange
    );
  };

  useEffect(() => {
    handleOnetrust();
    return () => {
      window.removeEventListener(
        'OneTrustGroupsUpdated',
        handleOneTrustGroupsChange
      );
    };
  });

  // useEffect(() => {
  //   activateFbPixel();
  // }, []);

  useLayoutEffect(() => {
    const $el = appContainerRef.current;
    const rs = new ResizeObserver(entries => {
      entries.forEach(entry => {
        const { width } = entry.contentRect;
        if (width >= MIN_DESKTOP_WIDTH && view !== 'desktop') {
          dispatch(uiSlice.actions.setView('desktop'));
        } else if (
          width >= MIN_TABLET_WIDTH &&
          width < MIN_DESKTOP_WIDTH &&
          view !== 'tablet'
        ) {
          dispatch(uiSlice.actions.setView('tablet'));
        } else if (width < MIN_TABLET_WIDTH && view !== 'mobile') {
          dispatch(uiSlice.actions.setView('mobile'));
        }
      });
    });

    if ($el) {
      // @ts-ignore
      rs.observe($el);
    }

    return () => {
      if ($el) {
        // @ts-ignore
        rs.unobserve($el);
      }
    };
  }, [dispatch, view]);
  const { pathname } = useLocation();
  const initializeEU = () => {
    if (isNative) {
      const oneTrustScript: any = document.getElementById(
        'onetrust-eu-cookies'
      );
      oneTrustScript?.remove();
    } else {
      if (apiAuthSuccess) {
        const isOneTrust = document.getElementById('onetrust-eu-cookies');
        if (!isOneTrust) {
          const script = document.createElement('script');
          // eslint-disable-next-line no-lone-blocks
          {
            [
              { key: 'type', value: 'text/javascript' },
              { key: 'charset', value: 'UTF-8' },
              { key: 'id', value: 'onetrust-eu-cookies' },
              {
                key: 'data-domain-script',
                value: `${process.env.REACT_APP_ONE_TRUST_COOKIE_TOKEN}`
              },
              {
                key: 'src',
                value: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
              }
            ].forEach(i => {
              script.setAttribute(i.key, i.value);
            });
          }
          document.head.appendChild(script);
        }
      }
    }
  };

  useEffect(() => {
    if (isNative) {
      const oneTrustScript: any = document.getElementById(
        'onetrust-eu-cookies'
      );
      oneTrustScript?.remove();
    } else {
      initializeEU();
    }
  }, [apiAuthSuccess, initializeEU, isNative]);

  useEffect(() => {
    const isRewards =
      pathname.includes('/rewards') || pathname.includes('getinvolved');
    if (apiAuthSuccess) {
      dispatch(fetchVoucher());
    }
  }, [pathname, apiAuthSuccess, dispatch]);

  useEffect(() => {
    if (apiAuthSuccess) {
      const isOneTrust = document.getElementById('onetrust-eu-cookies');
      if (!isOneTrust) {
        initializeEU();
      }
    }
  }, [apiAuthSuccess, initializeEU]);

  useEffect(() => {
    if (apiAuthSuccess) {
      dispatch(fetchMemberDetails());
      dispatch(fetchOffers());
      dispatch(fetchCommunityOffers());
      dispatch(fetchCharityOffers());
      dispatch(fetchRewards());
      dispatch(fetchVoucher());
      dispatch(fetchTransactions());
      dispatch(fetchProgressionPoint());
      dispatch(fetchMemberSavings());
      dispatch(fetchMemberGUID());
      dispatch(fetchUnsubscribePreferences());
    }
  }, [dispatch, apiAuthSuccess]);

  useEffect(() => {
    dispatch(authSlice.actions.setToken(token));
    dispatch(fetchStampsData()); // <- initial fetch

    if (isNative) {
      document.documentElement.style.setProperty(
        '--mobile-header-height',
        '0px'
      );
      document.documentElement.style.setProperty(
        '--native-header-padding',
        `${nativeHeaderPadding}px`
      );
    }
  }, [dispatch, isNative, token, nativeHeaderPadding]);

  const getNavigation = () => {
    if (isNative) return null;

    return view === 'mobile' ? <NavigationMobile /> : <NavigationDesktop />;
  };

  const logoutUrl = isNative ? '/logout' : '/logoutFlow/';

  return (
    <div className="app" ref={appContainerRef} data-testid="app-container">
      <ErrorBoundary>
        {blockingApiError ? (
          <BlockingError
            {...blockingApiError}
            isNative={isNative}
            cta={
              <ActionButton
                style={{
                  width: '85%'
                }}
                href={`${logoutUrl}/?apiKey=${blockingApiError.apiKey}`}
                text="Click here to log back in."
                external
              />
            }
          />
        ) : (
          <>
            {pathname != '/unsubscribe' && getNavigation()}
            <RetryFetch isNative={isNative} />
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route
                  exact
                  path="/offers/member-benefits/:id?"
                  render={() => <MemberBenefits isNative={isNative} />}
                />
                <Route
                  exact
                  path="/cookie-policy"
                  render={() => <CookiePolicy />}
                />
                <Route
                  exact
                  path="/logoutflow/"
                  render={() => <LogoutFlow />}
                />
                <Route
                  exact
                  path="/transaction/"
                  render={() => <TransactionPage />}
                />
                <Route
                  exact
                  path="/unsubscribe/"
                  render={() => <UnsubscribeContainer />}
                />
                <Route
                  exact
                  path={'/voucher/:id?'}
                  render={() => <Vouchers isNative={isNative} />}
                />
                <Route exact path="/voucher/" render={() => <VoucherHome />} />
                <Route
                  exact
                  path="/voucherHome/"
                  render={() => <VoucherHome />}
                />
                <Route
                  exact
                  path="/memberSavings/"
                  render={() => <MemberSavings />}
                />
                {navigation.map(
                  ({
                    component: Component,
                    path,
                    pathParams = '',
                    externalLink,
                    exact
                  }) => {
                    if (externalLink) return null;

                    return (
                      <Route
                        exact={exact}
                        path={`${path}${pathParams}`}
                        render={() => <Component isNative={isNative} />}
                        key={path}
                      />
                    );
                  }
                )}
                {process.env.REACT_APP_TARGET_ENV === 'local' && (
                  <Route exact path="/api" component={SwaggerUI} />
                )}
              </Switch>
            </Suspense>
          </>
        )}
      </ErrorBoundary>
    </div>
  );
};

const WithRouter: React.FC<AppPropsInterface> = props => (
  <BrowserRouter>
    <App {...props} />
  </BrowserRouter>
);

const AppWithoutRouter = App;

export { AppWithoutRouter };

export default WithRouter;
