import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { parseISO, format } from 'date-fns';
import moment from 'moment';

import classes from './Transactions.module.css';
import { RootState } from '../../appState/rootReducer';
import { TransactionsInterface } from '../../appState/transactionsSlice';
import ActionButton from '../../components/ActionButton/ActionButton';
import Loading from '../../components/Loading/Loading';
import BackButton from '../../components/BackButton/BackButton';

const Transactions: React.FC = () => {
  const { transactionsList, isLoader } = useSelector<RootState, TransactionsInterface>(
    ({ transactionsSlice }) => transactionsSlice
  );
  const divRef = useRef<HTMLDivElement>(null);
  const [isloader, setLoader] = useState(false);

  useEffect(() => {
    if (transactionsList.length > 0) {
      return setLoader(false);
    } else {
      return setLoader(true);
    }
  }, [transactionsList]);

  return (
    <>
    {!isLoader?(
      <div className={classes.page} ref={divRef}>
      <div className={classes.content}>
        <section className={classes.sopSection}>
          <div className={classes.transactions}>
            <div className={classes.list} data-testid="transactions">
              {transactionsList.length > 0 ? (
                transactionsList.map((item: { id: React.Key | null | undefined; tradingGroup: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; locationName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; value: number; dateTime: moment.MomentInput; }) => {
                  return (
                    <>
                      <div
                        className={classes.item}
                        key={item.id}
                        data-cy="transaction-item"
                      >
                        <div className={classes.details}>
                          <span className={classes.location}>
                            <span>{item.tradingGroup}</span>
                            <span>{item.locationName}</span>
                          </span>

                          <span className={classes.value}>
                            &#163;{item.value.toFixed(2)}
                          </span>
                        </div>
                        <div>
                          <span className={classes.date}>
                            {moment(item.dateTime).format('Do MMM YYYY')}
                          </span>
                        </div>
                      </div>
                    </>
                  );
                }
                )
              ) : (
                <div className={classes.noVoucher}>
                  <h2>No transactions to display</h2>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className={classes.ctaContainer}>
          <div className={classes.cta}>
            <ActionButton
              text="Find out more"
              href="https://mid.coop/more-transactions"
              external
            />
          </div>
        </section>
      </div>
    </div>
    ):<Loading/>}
      
    </>
  );
};

export default Transactions;
