import React from 'react';
import { useHistory } from 'react-router-dom';

import { ListItemInterface } from '../../types';
import classes from './ListView.module.css';
import Card from '../Card/Card';

interface Props {
  data: Array<ListItemInterface>;
  navigationPath: any;
  isCharity?: boolean;
}

const ListView: React.FC<Props> = ({ data, isCharity, navigationPath }) => {
  const history = useHistory();

  const renderListContent = (item: ListItemInterface) => {
    return (
      <>
      {isCharity? <Card data={item} isCharity={isCharity}/> : <Card data={item} isCharity={false}/>}
      </>
    );
  };

  const onkeydown = (e: any, index: number, path: string) => {
    history.push(path);
    if (e.key === 'Enter') {
      // sliderRef?.current?.slickGoTo(index);
      //setActiveStep(index);
    }
  };

  return (
    <>
      <section className={classes.listSection}>
        {data?.length > 0 && (
          <>
            {data[0].offerCategory ? (
              <div className={classes.listContainer}>
                <h2>{data[0].offerCategory}</h2>
              </div>
            ) : (
              data[0].region && data[0].region !== "General" ? (
                <div className={classes.listContainer}>
                  <h2>{data[0].region}</h2>
                </div>
              ) : (
                ''
              )
            )}

            <ol className={classes.list}>
              {data.map(
                (item, index) => (
                      <li key={item.id} className={classes.listItem}>
                        {item.redeemed ? (
                          renderListContent(item)
                        ) : (
                          <div
                            tabIndex={0}
                            role="button"
                            onKeyUp={e => {
                              onkeydown(
                                e,
                                index,
                                `${navigationPath}${item.id}`
                              );
                            }}
                            onClick={e => {
                              item?.isDirectLink == true ? window.open(item?.redemptionUrl, '_blank') : history.push( `${navigationPath}${item.id}`);
                              e.preventDefault();
                            }}
                            aria-label={`${item.heading1} ${item.description2}`}
                            data-testid="list-view-item"
                          >
                            {renderListContent(item)}
                          </div>
                        )}
                      </li>
                  )
                )
              }
            </ol>
          </>
        )}
      </section>
    </>
  );
};

export default ListView;
