import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import uiSlice from './uiSlice';
import { AppThunk } from './store';
import * as api from '../api';

export interface MemberDetailsInterface {
  sopPointsBalance: string;
  sopPointsBalanceDate: string;
}

const initialState: MemberDetailsInterface = {
  sopPointsBalance: '',
  sopPointsBalanceDate: ''
};

const memberDetailsSlice = createSlice({
  name: 'memberDetailsSlice',
  initialState,
  reducers: {
    setMemberDetails(state, action: PayloadAction<MemberDetailsInterface>) {
      return { ...action.payload };
    }
  }
});

export const fetchMemberDetails = (): AppThunk => async (
  dispatch,
  getState
) => {
  const apiKey = 'fetchMemberDetails';
  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));
  try {
    const { token } = getState().authSlice;
    const memberDetails = await api.fetchMemberDetails(token as string);
    dispatch(memberDetailsSlice.actions.setMemberDetails(memberDetails));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default memberDetailsSlice;
