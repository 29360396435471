import React from 'react';
import classes from './Card.module.css';
import { ListItemInterface } from '../../types';
import Arrow from '../../assets/images/arrow.svg';
import Infinity from '../../assets/images/Subtract.png';

interface CardProps {
  data: ListItemInterface;
  isCharity?: boolean;
}

const Card: React.FC<CardProps> = ({ data, isCharity }) => { 
  const discountPrices = !Boolean(data?.offerPrice) || data?.offerPrice !== "0" ? data?.offerPrice?.toString()
    : '';
  const currentPrice =!Boolean(data?.wasPrice) ||  data?.wasPrice !== "0" ? data.wasPrice?.toString()
    : '';
    
  return (
    <div className={classes.card}>
      <img src={data.imgUrlSmall} alt={data.altText} />
      <div className={classes.container}>
        <div>
          {data.offerBonusText && (
            <button className={classes.roundedBtn}>
              <span className={classes.roundedIcon}>
                <img alt="" src={Infinity} />
              </span>
              <span className={classes.roundedTxt}>{data.offerBonusText}</span>
            </button>
          )}
          {discountPrices ? (
            <div className={classes.flexLeftRightPrice}>
              <h2>{discountPrices?`£${discountPrices}`:''}</h2>
              <h3 className={classes.currentPrice}>{currentPrice?`£${currentPrice}`:''}</h3>
            </div>
          ) : (
            <div className={classes.flexLeftRightPrice}>
              <h2>{currentPrice?`£${currentPrice}`:''}</h2>
            </div>
          )}
        </div>
        <div className={classes.flexLeftRight}>
          <div>
            <h3 style={{ fontWeight: 'bold' }}>{data.heading1}</h3>
            {isCharity ? (
              <div>
                <p>{data.description2}</p>
              </div>
            ) : (
              ''
            )}
          </div>
          <img
            src={Arrow}
            alt="arrow"
            style={{ width: 'auto', height: 'auto', margin: 'auto' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Card;
