// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4cTplvh9UshL4HjfjIONyA\\=\\= {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  background: #ff5c39;\n  z-index: 4;\n  color: #fff;\n  font-size: 14px;\n  padding: 10px;\n  border: 0;\n  outline: 0;\n \n  transition: all 0.2s ease-in-out;\n  opacity: 1;\n  transform: translateY(0);\n  white-space: pre-line;\n}\n\n.j4Rji07kfAs7DAnNf-tNwQ\\=\\= {\n  opacity: 0;\n  transform: translateY(100%);\n  pointer-events: none;\n}\n", "",{"version":3,"sources":["webpack://src/components/Toast/Toast.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,mBAAmB;EACnB,UAAU;EACV,WAAW;EACX,eAAe;EACf,aAAa;EACb,SAAS;EACT,UAAU;;EAEV,gCAAgC;EAChC,UAAU;EACV,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EAEE,UAAU;EACV,2BAA2B;EAC3B,oBAAoB;AACtB","sourcesContent":[".retryButton {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  background: #ff5c39;\n  z-index: 4;\n  color: #fff;\n  font-size: 14px;\n  padding: 10px;\n  border: 0;\n  outline: 0;\n \n  transition: all 0.2s ease-in-out;\n  opacity: 1;\n  transform: translateY(0);\n  white-space: pre-line;\n}\n\n.retryButtonHidden {\n  composes: retryButton;\n  opacity: 0;\n  transform: translateY(100%);\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"retryButton": "_4cTplvh9UshL4HjfjIONyA==",
	"retryButtonHidden": "j4Rji07kfAs7DAnNf-tNwQ== _4cTplvh9UshL4HjfjIONyA=="
};
export default ___CSS_LOADER_EXPORT___;
