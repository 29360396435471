import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../appState/rootReducer';
import { UiInterface } from '../../appState/uiSlice';
import { fetchTransactions } from '../../appState/transactionsSlice';
import { fetchStampsData } from '../../appState/stampsSlice';
import { fetchMemberBenefits } from '../../appState/memberBenefitsSlice';
import { fetchOffers } from '../../appState/offersSlice';
import { fetchCommunityOffers } from '../../appState/communitySlice';
import { fetchProgressionPoint } from '../../appState/progressionPointSlice';
import { fetchCharityOffers } from '../../appState/charitySlice';
import { fetchRewards } from '../../appState/rewardsSlice';
import { fetchVoucher } from '../../appState/voucherSlice';
import { fetchUserPreferences } from '../../appState/userSettingsSlice';
import { fetchMemberDetails } from '../../appState/memberDetailsSlice';
import {fetchMemberGUID} from '../../appState/memberGUIDSlice';

import classes from './RetryFetch.module.css';

interface FetchesInterface {
  fetchStampsData: () => void;
  fetchTransactions: () => void;
  fetchMemberBenefits: () => void;
  fetchOffers: () => void;
  fetchCommunityOffers: () => void;
  fetchCharityOffers: () => void;
  fetchRewards: () => void;
  fetchVoucher: () => void;
  fetchUserPreferences: () => void;
  fetchMemberDetails: () => void;
  fetchProgressionPoint: () => void;
  fetchMemberGUID: () => void;
}

const fetches: FetchesInterface = {
  fetchTransactions,
  fetchStampsData,
  fetchMemberBenefits,
  fetchOffers,
  fetchCommunityOffers,
  fetchCharityOffers,
  fetchRewards,
  fetchVoucher,
  fetchUserPreferences,
  fetchMemberDetails,
  fetchProgressionPoint,
  fetchMemberGUID
};

interface RetryFetch {
  isNative: boolean;
}
/**
 * Note, this only retries GETs that have failed and been
 * added to uiSlice > inlineApiErrors.
 * This currently will display on every page,
 * i.e the page may not be related to the api call that failed
 */

const RetryFetch: React.FC<RetryFetch> = ({ isNative }) => {
  const dispatch = useDispatch();
  const { inlineApiErrors } = useSelector<RootState, UiInterface>(
    ({ uiSlice }) => uiSlice
  );

  const [isOnline, setOnlineStatus] = useState(true);

  const fetchApiErrors = useMemo(() => {
    return inlineApiErrors.filter(({ type }) => type !== 'post');
  }, [inlineApiErrors]);

  const offlineHandler = () => {
    setOnlineStatus(false);
  };
  const onlineHandler = () => {
    setOnlineStatus(true);
  };

  useEffect(() => {
    const padding = fetchApiErrors.length ? '50px' : '0px';

    document.documentElement.style.setProperty(
      '--mobile-footer-padding',
      padding
    );

    window.addEventListener('offline', offlineHandler);
    window.addEventListener('online', onlineHandler);

    return function cleanup() {
      window.removeEventListener('online', onlineHandler);
      window.removeEventListener('offline', offlineHandler);
    };
  }, [fetchApiErrors]);

  const handleRetry = () => {
    if (isOnline && !isNative) {
      fetchApiErrors.forEach(({ apiKey }) => {
        // ignoring as we have filtered out the post calls which expects arguments
        // @ts-ignore
        dispatch(fetches[apiKey]());
      });
    }
  };

  const getErrorMessage = () => {
    if (!isOnline || !navigator.onLine) {
      return 'No internet connection.';
    } else {
      if (isNative) return 'Something went wrong. Pull down to refresh.';
      else return 'Something went wrong. Please try again.';
    }
  };

  const isHidden = fetchApiErrors.length === 0;

  return (
    <button
      className={
        fetchApiErrors.length || !isOnline
          ? classes.retryButton
          : classes.retryButtonHidden
      }
      type="button"
      onClick={handleRetry}
      aria-hidden={isHidden}
      role={"link"}
      data-testid="retry-fetch"
      tabIndex={fetchApiErrors.length || !isOnline ? 0 : -1}
    >
      {getErrorMessage()}
    </button>
  );
};

export default RetryFetch;
