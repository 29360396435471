/* eslint-disable react/display-name */
import React from 'react';
import classes from './VoucherDetail.module.css';

export const VOUCHER_TYPE = {
  SHARE_OF_PROFIT: 'share of the profits',
  THANK_YOU: 'colleague thank you',
  MEMBER_THANK_YOU: 'member thank you'
};

const AppName = 'Your Co-op members';

const list = {
  sop: [
    `This voucher cannot be used on purchases with any other Co-operative Societies`,
    `It can only be used by displaying it on the ${AppName} app or website.`,
    `Printed copies will not be accepted.`,
    `Each voucher must only be used once.`,
    `This voucher must be used in a single transaction. However, if your purchase value is lower than the voucher value, you won’t lose the rest. If you are shopping in a branch you will get the balance in cash. If you are shopping online the remainder will be held on account for when you request it.`,
    `You can use more than one voucher in a single transaction.`,
    `The voucher can be used up to 22 January 2022. After this date it will be removed from ${AppName} app. If you don’t use your vouchers, you won’t miss out – we will hold the money for you on account for when you request it.`
  ],
  thankyou: []
};

export const HowToRedeem = (props: any) => {
  const { voucherType: vType, isNovSOP } = props;
  const voucherType = [
    VOUCHER_TYPE.SHARE_OF_PROFIT,
    VOUCHER_TYPE.MEMBER_THANK_YOU,
    VOUCHER_TYPE.THANK_YOU
  ].includes(vType)
    ? vType
    : VOUCHER_TYPE.SHARE_OF_PROFIT;
  const obj = {
    [VOUCHER_TYPE.SHARE_OF_PROFIT]: () =>
      (isNovSOP && (
        <>
          {`Open the voucher on the ${AppName} app or website. Present it when you
          pay at any Midcounties Co-operative branch – whether it’s food, childcare or travel.`}

          {
            ' , log in with your membership details and type in the voucher code at the online checkout.'
          }
        </>
      )) || (
        <>
          {`Open the voucher on the ${AppName} app or website. Present it when you
        pay at any Midcounties Co-operative branch – whether it’s food,
        childcare, travel or funeral. `}

          {
            ' , log in with your membership details and type in the voucher code at the online checkout.'
          }
        </>
      ),
    [VOUCHER_TYPE.MEMBER_THANK_YOU]: () => (
      <>
        {`Open the voucher on the ${AppName} app or website. Present it when you pay at any Midcounties Co-operative Food store, Co-operative Travel branch, Co-op Childcare nursery, Little Pioneers nursery, Funeral branch.`}

        {` and log in with your membership details. Type in the voucher code at the online checkout.`}
      </>
    ),
    [VOUCHER_TYPE.THANK_YOU]: () => (
      <>
        {`Open the voucher on the ${AppName} app or website. Present it when you pay at any Midcounties Co-operative Food store, Co-operative Travel branch, Co-op Childcare nursery, Little Pioneers nursery, Funeral branch. You can use it in Your Co-op Broadband via an online form `}
        <a
          href={
            'https://forms.office.com/pages/responsepage.aspx?id=JS0nqCAQikOl0KOM0_84sENB8kcRbqpKgAv-vBejSXhUMUNYWUI4Uk5TSkc3S0ZHS1JEU0NSWEtLNy4u'
          }
          className={classes.externalLink}
          target={'_blank'}
          rel="noopener noreferrer"
          aria-label="here"
        >
          here.
        </a>

        {
          ' and log in with your membership details. Type in the voucher code at the online checkout.'
        }
      </>
    )
  };

  const HwToComponent = obj[voucherType];
  if (vType === VOUCHER_TYPE.SHARE_OF_PROFIT) {
    return (
      <div className={classes.topPadding} aria-label={'How to redeem'}>
        <h2>{'How to redeem'}</h2>
        <p>
          <HwToComponent />
        </p>
      </div>
    );
  }
  return null;
};

export const About = (props: any) => {
  const { voucherType: vType, isNovSOP } = props;
  const voucherType = [
    VOUCHER_TYPE.SHARE_OF_PROFIT,
    VOUCHER_TYPE.MEMBER_THANK_YOU,
    VOUCHER_TYPE.THANK_YOU
  ].includes(vType)
    ? vType
    : VOUCHER_TYPE.SHARE_OF_PROFIT;
  const obj = {
    [VOUCHER_TYPE.SHARE_OF_PROFIT]: isNovSOP
      ? 'This voucher is your share of the profits for the 2020/2021 financial year.'
      : 'This voucher is your share of the profits for the 2019/2020 financial year and is one of the ways in which we reward our members.  It can be used when you pay in our food, travel, childcare or funeral branches or for online healthcare purchases.',
    [VOUCHER_TYPE.THANK_YOU]:
      'This voucher is your Colleague Dividend for the 2020/2021 financial year and is one of the ways in which we reward our colleagues who are members for the work that they have done for the Society during the financial year (26 January 2020 – 23 January 2021) to which the vouchers relate. They can be used in Midcounties Co-operative Food stores, Co-operative Travel branches, Co-op Childcare nurseries, Little Pioneers nurseries, Funeral branches, Your Co-op Broadband.',
    [VOUCHER_TYPE.MEMBER_THANK_YOU]:
      'This voucher is your Member Thank You voucher, which reflects your previous Stamps balance. They can be used in Midcounties Co-operative Food stores, Co-operative Travel branches, Co-op Childcare nurseries, Little Pioneers nurseries, Funeral branches.​'
  };

  if (vType === VOUCHER_TYPE.SHARE_OF_PROFIT) {
    return (
      <div aria-label="About this voucher">
        <h2>{'About this voucher'}</h2>
        <p>{obj[voucherType]}</p>
      </div>
    );
  }

  return null;
};

export const TermsCondition = (props: any) => {
  const { type, voucherType, isNovSOP } = props;
  const isSOP = type === VOUCHER_TYPE.SHARE_OF_PROFIT; // any other than thank you
  const isCollgueThankYou = voucherType === VOUCHER_TYPE.THANK_YOU;
  let termsText =
      `See details for how you can use your vouchers at  `;

  if (isCollgueThankYou || voucherType === VOUCHER_TYPE.MEMBER_THANK_YOU) {
    termsText = 'See details for how you can use your vouchers at ';
  }
  const termsList = (isSOP && list.sop) || list.thankyou;
  const isCopyLink = isSOP ? isNovSOP : true;
  if (voucherType === VOUCHER_TYPE.MEMBER_THANK_YOU) {
    return (
      <section className={classes.cell} aria-label={'Terms & Conditions '}>
          <h3>{'Terms & Conditions'}</h3>
      
        <p className={classes.terms} aria-label={termsText}>
          {termsText}
          <a
            className={classes.externalLink}
            href={'https://mid.coop/app-tandc-mem'}
            target={'_blank'}
            rel="noopener noreferrer"
            aria-label=" mid.coop/app-tandc-mem"
          >
            mid.coop/app-tandc-mem
          </a>
        </p>
      </section>
    );
  }

  if (voucherType === VOUCHER_TYPE.THANK_YOU) {
    return (
      <section className={classes.cell} aria-label={'Terms & Conditions '}>
          <h3>{'Terms & Conditions'}</h3>
        
        <p className={classes.terms} aria-label={termsText}>
          {termsText}
          <a
            className={classes.externalLink}
            href={'https://mid.coop/app-tandc-col'}
            target={'_blank'}
            rel="noopener noreferrer"
            aria-label=" mid.coop/app-tandc-col"
          >
            mid.coop/app-tandc-col
          </a>
        </p>
      </section>
    );
  }
  if (isCopyLink) {
    return (
      <section className={classes.cell} aria-label={'Terms & Conditions '}>
        {voucherType === VOUCHER_TYPE.SHARE_OF_PROFIT && (
          <h3>{'Terms & Conditions'}</h3>
        )}
        <p className={classes.terms} aria-label={termsText}>
          {termsText}
          <a
            className={classes.externalLink}
            href={'https://mid.coop/app-tandc-sop'}
            target={'_blank'}
            rel="noopener noreferrer"
            aria-label=" mid.coop/app-tandc"
          >
            mid.coop/app-tandc-sop
          </a>
        </p>
      </section>
    );
  }
  

  return (
    <section className={classes.cell}>
        <h3>{'Terms & Conditions'}</h3>
      <p className={classes.terms}>
        {termsText}
        {isSOP && (
          <a
            className={classes.externalLink}
            href={'https://mid.coop/app-tandc-sop'}
            target={'_blank'}
            rel="noopener noreferrer"
            aria-label="mid.coop/app-tandc"
          >
            mid.coop/app-tandc-sop
          </a>
        )}
          
      </p>
    </section>
  );
};
