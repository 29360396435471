import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import { ListItemInterface } from '../types';
import * as api from '../api';
import moment from 'moment';
import RewardsDataMock from '../mock-data/vouchers.json';
export interface RewardsInterface {
  rewardsList: Array<ListItemInterface>;
}

const initialState: RewardsInterface = {
  rewardsList: []
};

const rewardsSlice = createSlice({
  name: 'rewardsSlice',
  initialState,
  reducers: {
    setRewardsData(state, action: PayloadAction<ListItemInterface[]>) {
      state.rewardsList = action.payload;
    }
  }
});

export const fetchRewards = (): AppThunk => async (dispatch, getState) => {
  const apiKey = 'fetchRewards';
  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const rewardsData = await api.fetchRewards(token as string);
    //const rewardsData = RewardsDataMock;

    const newRewardsData = [] as any;
    if (rewardsData?.length) {
      rewardsData.forEach((item: any) => {
        item['isClaimed'] = item?.redemptionStatus === 'claimed';
        item['redeemed'] = item?.redemptionStatus === 'redeemed';
        item['endDate'] = item?.endDate
          ? moment.unix(item.endDate).format('DD/MM/YYYY')
          : null;

        newRewardsData.push(item);
      });
    }
    dispatch(rewardsSlice.actions.setRewardsData(newRewardsData));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default rewardsSlice;
