import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../appState/rootReducer';
import { UiInterface } from '../../appState/uiSlice';
import classes from './Banner.module.css';
import { ReactComponent as BannerLeftCircles } from './BannerLeftCircles.svg';
import { ReactComponent as BannerRightCircles } from './BannerRightCircles.svg';

interface BannerProps {
  heading?: string;
  text?: string;
  imgSrc?: string;
  imgAltText?: string;
  backgroundColor?: string;
  textColor?: string;
  cropImage?: boolean;
  imagePositionExtremeRight?: boolean;
  isStampsRewards?: boolean;
  heading2?: string;
  fullText?: boolean;
}

const Banner: React.FC<BannerProps> = ({
  heading = '',
  text,
  imgSrc,
  imgAltText,
  backgroundColor,
  textColor,
  cropImage,
  imagePositionExtremeRight,
  heading2 = '',
  fullText = false
}) => {
  const { view } = useSelector<RootState, UiInterface>(
    ({ uiSlice }) => uiSlice
  );

  const style = {
    backgroundColor: '#edb84d',
    color: textColor,
    display: 'flex'
  };
  const textProps = fullText ? { width: '100%' } : {};
  return (
    <div className={classes.banner} style={style} data-testid="hero-banner">
      <div className={classes.container}>
        <div className={classes.circleLeft}>
          <BannerLeftCircles aria-label="Banner left circle image" />
        </div>
        <div
          aria-label={`${heading} ${heading2 ? heading2 : ''} ${text?text:''}`}
          role="textbox"
          tabIndex={0}
          className={classes.textContainer}
          style={textProps}
        >
          <h1 className={classes.heading}>{heading}</h1>
          {heading2 && <h2 className={classes.heading2}>{heading2}</h2>}
          <p className={classes.text}>{text}</p>
        </div>
        {imagePositionExtremeRight ? null : (
          <img
            aria-label="banner"
            className={cropImage ? classes.croppedImage : classes.image}
            src={imgSrc}
            alt={'bannerImage'}
            role="banner"
          />
        )}
        <div className={classes.circleRight}>
          <BannerRightCircles aria-label="right circle image" />
        </div>
      </div>
      {imagePositionExtremeRight ? (
        <img
          className={classes.bannerImageRight}
          src={imgSrc}
          alt={'Right ' + imgAltText}
          style={{ width: '50%' }}
        />
      ) : null}
      <svg width="0" height="0">
        <defs>
          <clipPath id="myClip">
            {view === 'tablet' ? (
              <>
                <circle cx="180" cy="80" r="110" />
                <circle cx="355" cy="175" r="110" />
              </>
            ) : (
              <>
                <circle cx="150" cy="100" r="145" />
                <circle cx="370" cy="180" r="145" />
              </>
            )}
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Banner;
