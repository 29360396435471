import React from 'react';
import { useHistory } from 'react-router-dom';
import Icon from '../Icon/Icon';
import classes from './BackButton.module.css';

interface BackButton {
  hideText?: boolean;
}

const BackButton: React.FC<BackButton> = ({ hideText }) => {
  const history = useHistory();
  return (
    <button
      className={classes.backButton}
      type="button"
      onClick={history.goBack}
      data-testid="back-button"
      role={'link'}
      tabIndex={hideText === true ? -1 : 0}
    >
      <div className={classes.icon}>
        <Icon iconName="backArrow" />
      </div>
    </button>
  );
};

export default BackButton;
