import React, { useState, useEffect, useRef } from 'react';

import classes from './Interests.module.css';
import InterestCheckbox from '../../components/InterestCheckbox/InterestCheckbox';
import ActionButton from '../../components/ActionButton/ActionButton';
import BackButton from '../../components/BackButton/BackButton';
import Checkbox from '../../components/Checkbox/Checkbox';
import {
  OptionItemInterface,
  SectionInterface
} from '../../appState/userSettingsSlice';
import { editmail, lovechat } from '../../assets/images';
import DialogBox from '../../components/DialogBox/DialogBox';
import ConfirmationPrompt from '../../components/DialogBox/ConfirmationPrompt';
import ConfirmBox from '../../components/ConfirmationBox/ConfirmationBox';

interface InterestContainerProps {
  sectionList: Array<SectionInterface> | undefined;
  handleUpdateOption: (id: string) => void;
  handleUpdateOptions: (id: string, firstSelection: boolean) => void;
  shouldUpdate: boolean;
  updatePreferences: () => void;
  showBackButton: boolean;
  updateSubscription: (type: 'subscribe' | 'unsubscribe') => void;
  toast: any;
  sendUpdateFunction: any;
  shouldUpdateFromPopup: any;
  updateSettingStateToInitialValue?: any;
}

const InterestContainer: React.FC<InterestContainerProps> = (
  props: InterestContainerProps
) => {
  const {
    sectionList = [],
    handleUpdateOption,
    handleUpdateOptions,
    shouldUpdate,
    updatePreferences,
    showBackButton,
    updateSubscription,
    sendUpdateFunction,
    shouldUpdateFromPopup,
    updateSettingStateToInitialValue
  } = props;
  const [section1, section2, section3] = sectionList;

  const [toggle, setToggle] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [options, setOptions] = useState<Array<OptionItemInterface>>([]);
  const [atleastOneInterest, setAtleastOneInterest] = useState(false);
  const [hideConfirmBox, setHideConfirmBox] = useState(true);
  const [updateAllPreferences, setUpdateAllPreferences] = useState(false);


  useEffect(() => {
    if (section3?.options) {
      setOptions([...section3.options]);
    }
  }, [section3]);

  const isFoundArray = () => {
    return section3?.options.some(item => {
      return item.selected === true ? true : false;
    });
  };
  const isNotSelected = isFoundArray();

  const isFoundArrays = () => {
    return section2?.options.some(item => {
      return item.selected === true ? true : false;
    });
  };
  const isNotSelectedContent = isFoundArrays();
  // const handleUpdateOptionCallback = (id: string) => {
  //   if (toggle) setToggle(false);
  //   handleUpdateOption(id);
  // };

  const handleUpdateSubscription = () => {
    if (toggle) {
      if (toggle) setToggle(false);
    } else {
      updatePreferences();
    }
  };
  const handaleToggle = (e: any) => {
    setToggle(!toggle);
    if (e.target.checked) {
      setConfirm(true);
    } else {
      console.log("else")
      setOptions([...section3.options]);
    }
    // setToggle(!toggle);
    //setTimeout(() => {
    //
    // }, 500);
    // updateSubscription('unsubscribe');
  };

  const handleCallBack = () => {
    setOptions(prevState => {
      // Loop over your list
      return prevState.map(item => {
        // Check for the item with the specified id and update it
        return { ...item, selected: false };
      });
    });
  };

  const handlePreferencesSelection = (optionID: string, firstSelection: boolean) => {
    if (section2.options.every(option => !option.selected)) {
      setConfirm(false);
      handleUpdateOptions(optionID, true)
      setToggle(false)
      setUpdateAllPreferences(true)
    } else {
      handleUpdateOptions(optionID, firstSelection)
    }
  }

  useEffect(() => {
    if (section3?.options) {
      const atLeaseOneSelected = section3.options.every(option => !option.selected);
      setAtleastOneInterest(!atLeaseOneSelected)
    }
  }, [section3]);

  const handleCancelConfirmation = () => {
    setUpdateAllPreferences(true)
    updateSettingStateToInitialValue()
  };


  const updatePreferencesOptions = () => {
    if (atleastOneInterest === true && section2.options.every(option => !option.selected) && !section3.options.every(option => option.selected)) {
      handleUpdateOptions(section2.options[0].optionId, false)
      setToggle(false)
    } else {
      handleUpdateSubscription();
    }
  };

  const handleUpdateOptionCall = (optionId: any) => {
    setUpdateAllPreferences(false);
    if (optionId) {
      setToggle(false)
    }
    handleUpdateOption(optionId)
  };

  useEffect(() => {
    if (shouldUpdateFromPopup) {
      sendUpdateFunction(updatePreferencesOptions);
    }
  }, [shouldUpdateFromPopup]);


  return (
    <div className={classes.settingsContainer}>
      <ConfirmationPrompt visible={!shouldUpdate && !updateAllPreferences || (toggle && confirm)} />
      <div className={classes.interests}>
        {showBackButton && (
          <div className={classes.backButton}>
            <BackButton />
          </div>
        )}
        {/* <div className={classes.sections}>
        <div className={classes.settingsContainers }> */}
        <section role="group" aria-labelledby={'Communication preferences'}>
          <div aria-label={`Communication preferences${section1?.summary.label} ${section2?.summary.label}`} tabIndex={0} role="textbox">
            <h1
              aria-label=" Communication preferences"
              className={classes.sectionHeading}
            >
              Communication preferences{' '}
              <img
                aria-label="image"
                src={editmail}
                style={{ width: '20px', height: '20px', marginLeft: '5px' }}
                alt="Email-icon"
              />
            </h1>

            <p
              className={classes.sectionSummary}
            // aria-label={section2?.summary.label}
            >
              {section1?.summary.label} {section2?.summary.label}
            </p>
          </div>
          {/* <div className={classes.ctaSubscribe}>
                <ActionButton
                  text="Subscribe"
                  onClick={() => setSubscriptionMethod('subscribe')}
                />
              </div>
              <p className={classes.sectionSummary}>
                {'If you prefer not to hear from us click '}
                <a
                  href={'#'}
                  onClick={() => setSubscriptionMethod('unsubscribe')}
                >
                  here
                </a>
              </p> */}

          {section2?.options.map(
            ({ optionId, label, selected }: OptionItemInterface) => {
              return (
                <div className={classes.fields} key={optionId}>
                  <div
                    role="checkbox"
                    aria-checked={selected}
                    tabIndex={0}
                    className={classes.checkBoxContainer}
                    aria-label={label}
                  >
                    <Checkbox
                      id={optionId}
                      text={label}
                      value={selected}
                      onChange={() => handlePreferencesSelection(optionId, false)}
                    //alignLeft={view !== 'mobile'}
                    />
                  </div>
                </div>
              );
            }
          )}
        </section>
        <div aria-label=" Manage your interest" role="textbox" tabIndex={0}>
          <h1 className={classes.sectionHeading}>
            Manage your interest{' '}
            <img
              src={lovechat}
              style={{ width: '20px', height: '20px', marginLeft: '5px' }}
              alt="img-icon"
            />
          </h1>

          <p className={classes.sectionSummary}>{section3?.summary.label}</p>
        </div>
        <div
          className={classes.interestGrid}
          role={'group'}
          aria-labelledby={section3?.summary.label}
        >
          {options.map(
            ({ optionId, label, value, selected }: OptionItemInterface) => {
              return (
                <div
                  className={classes.interest}
                  key={optionId}
                  role="checkbox"
                  aria-checked={selected}
                  tabIndex={0}
                >
                  <InterestCheckbox
                    id={optionId}
                    text={label}
                    value={value}
                    selected={selected}
                    onChange={() => handleUpdateOptionCall(optionId)}
                  />
                </div>
              );
            }
          )}
        </div>

        <section>
          <div
            aria-label="Prefer not to hear from us"
            role={'group'}
            aria-labelledby={'Prefer not to hear from us? '}
          >
            <h1
              className={classes.sectionHeading}
              aria-label="Prefer not to hear from us?"
            >
              Prefer not to hear from us?{' '}
            </h1>
            <div style={{ padding: '20px' }}>
              <div
                className={classes.checkBoxContainer}
                role="checkbox"
                aria-checked={toggle}
                tabIndex={0}
                aria-label="If you select this we will no longer send you marketing communications"
              >
                <Checkbox
                  id="hello"
                  text="If you select this we will no longer send you marketing communications"
                  value={(!isNotSelected && !isNotSelectedContent) || toggle}
                  onChange={e => handaleToggle(e)}
                  headerTextStyle={{ lineHeight: '22px', flex: 1, marginRight: '20px' }}
                  disabled={!isNotSelected && !isNotSelectedContent}
                //alignLeft={true}
                />
              </div>
            </div>
          </div>
        </section>
        {/* <div
        aria-label="Confirm-button"
        role="button"
        tabIndex={0}
        className={classes.cta}
      >
        <ActionButton
          disabled={!shouldUpdate || toggle ? false : true}
          text="Confirm"
          onClick={() => handleUpdateSubscription()}
        />
      </div> */}
        <ConfirmBox
          visible={!shouldUpdate && !updateAllPreferences}
          cancelCallback={handleCancelConfirmation}
          cancelText={'Cancel'}
          successCallback={() => {
            updatePreferencesOptions();
          }}
          successText="Confirm my changes"
        />

        <ConfirmBox
          visible={toggle && confirm}
          cancelCallback={() => {
            setToggle(false);
            setConfirm(false)
          }}
          cancelText={'Cancel'}
          successCallback={() => {
            updateSubscription('unsubscribe');
            setConfirm(false)
          }}
          successText="Confirm my changes"
        />

        {/* <DialogBox
        visible={confirm}
        cancelCallback={() => {
          setConfirm(false);
          handleCallBack();
          setToggle(false);
        }}
        cancelText={'Cancel'}
        successCallback={() => {
          setConfirm(true);
          setOptions([...section3.options]);
          setToggle(true);
        }}
        successText="Ok"
        headerText=""
        contentText="You have opted out of all marketing communications. Click 'Ok' and then 'Confirm’."
      /> */}
      </div>
    </div>
  );
};

export default InterestContainer;