import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import uiSlice from './uiSlice';
import * as api from '../api';
import transactionsMockData from '../mock-data/transactions.json';
export interface TransactionsItemInterface {
  id: string;
  dateTime: string;
  tradingGroup: string;
  locationName: string;
  value: number;
}

export interface TransactionsInterface {
  transactionsList: any;
  isLoader:boolean;
}

const initialState: TransactionsInterface = {
  transactionsList: [],
  isLoader: true
};

const transactionsSlice = createSlice({
  name: 'transactionsSlice',
  initialState,
  reducers: {
    setTransactionsData(
      state,
      action: PayloadAction<TransactionsInterface>
    ) {
      state.transactionsList = action.payload.transactionsList;
      state.isLoader = action.payload.isLoader;
    }
  }
});

export const fetchTransactions = (): AppThunk => async (dispatch, getState) => {
  const apiKey = 'fetchTransactions';
  // Todo: handle loading states...

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const response = await api.fetchTransactions(token as string);
    // const response = transactionsMockData;
    const newTransactionData = [] as any;
    const transactionsData = (response.content as any[]).map(
      ({
        eventLineId,
        eventDateTime,
        tradingGroup,
        locationName,
        eventValue
      }) => {
        return {
          id: eventLineId,
          dateTime: eventDateTime,
          tradingGroup: tradingGroup,
          locationName: locationName,
          value: eventValue
        };
      }
    );

    const payload = {
      transactionsList: transactionsData,
      isLoader:transactionsData?false:true
    };

    dispatch(transactionsSlice.actions.setTransactionsData(payload));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default transactionsSlice;
