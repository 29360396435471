// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SAkH\\+OwvtZ9S6GK2IfMtbA\\=\\= {\n  padding-top: calc(50px + var(--native-header-padding));\n  overflow-x: hidden;\n}\n\n.a0dEFXxsV6AzXGQEcl7dOg\\=\\= {\n  position: relative;\n  background-color: white;\n  z-index: 3;\n}\n\n@media (min-width: 768px) {\n  .SAkH\\+OwvtZ9S6GK2IfMtbA\\=\\= {\n    padding-top: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/screens/MemberBenefits/MemberBenefits.module.css"],"names":[],"mappings":"AAAA;EAEE,sDAAsD;EACtD,kBAAkB;AACpB;;AAEA;EAEE,kBAAkB;EAClB,uBAAuB;EACvB,UAAU;AACZ;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".memberBenefits {\n  composes: screen from global;\n  padding-top: calc(50px + var(--native-header-padding));\n  overflow-x: hidden;\n}\n\n.section {\n  composes: content from global;\n  position: relative;\n  background-color: white;\n  z-index: 3;\n}\n\n@media (min-width: 768px) {\n  .memberBenefits {\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"memberBenefits": "SAkH+OwvtZ9S6GK2IfMtbA== screen",
	"section": "a0dEFXxsV6AzXGQEcl7dOg== content"
};
export default ___CSS_LOADER_EXPORT___;
