import React, { ReactElement } from 'react';
import { ERRORS } from '../../utils';
import classes from './BlockingError.module.css';

interface BlockErrorInterface {
  heading: string;
  message: string;
  message2?: string;
  cta: ReactElement;
  isNative?: boolean;
}

const BlockingError: React.FC<BlockErrorInterface> = ({
  heading,
  message,
  message2,
  cta,
  isNative
}) => {
  const msg =
    message === ERRORS.UNAUTHORIZED.MOBILE_MSG1
      ? isNative
        ? ERRORS.UNAUTHORIZED.MOBILE_MSG1
        : ERRORS.UNAUTHORIZED.WEB_MSG1
      : message;
  return (
    <div className={classes.blockingError}>
      <h1 className={classes.heading}>{heading}</h1>
      <p>{msg}</p>
      {message2 && <p>{message2}</p>}
      {cta}
      <p>Still not working? <a href="https://mid.coop/app-trouble">click here</a></p>
    </div>
  );
};

export default BlockingError;