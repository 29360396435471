import React from 'react';
import { editmail } from '../../assets/images';
import classes from './DialogBox.module.css';

interface Props {
  visible?: boolean | undefined;
  cancelText?: string;
  successText?: string;
  headerText?: string;
  contentText?: string;
  successCallback?(): void;
  cancelCallback?(): void;
}
const DialogSuccessBox = ({
  visible,
  successCallback,
  successText,
  contentText
}: Props) => {
  return (
    <>
      {visible ? (
        <div className={classes.mainContainer}>
          <div className={classes.successdialogcontainer}>
            <div className={classes.successimagecontainer}>
              <img className={classes.successimg} alt="" src={editmail} />
            </div>
            <h4 className={classes.successcontent}>{contentText}</h4>
            <div className={classes.buttonContainer}>
              <button
                className={classes.buttonSecond}
                onClick={successCallback}
              >
                {successText}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default DialogSuccessBox;
