import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackButton from '../BackButton/BackButton';
import classes from './ParentNavChildNav.module.css';

interface LinkItems {
  path: string;
  text: string;
  childHeading: string;
  selected: boolean;
}
interface ParentNavChildNavProps {
  parentLinks: Array<LinkItems>;
  currentPage: string;
}
const ParentNavChildNav: React.FC<ParentNavChildNavProps> = ({
  parentLinks,
  currentPage
}) => {
  const showChild = parentLinks.every(({ path }) => path !== currentPage);
  const selectedItem = parentLinks.find(({ selected }) => selected) || {
    childHeading: '',
    path: '',
    text: ''
  };

  const renderParentLinks = () => {
    return (
      <div
        className={classes.nav}
        style={{
          opacity: showChild ? 0 : 1,
          pointerEvents: showChild ? 'none' : 'all'
        }}
      >
        {parentLinks?.map(({ path, text, selected }) => {
          return (
            <Link
              role={'link'}
              tabIndex={showChild === false ? 0 : -1}
              key={path}
              to={path}
              className={selected ? classes.navItemSelected : classes.navItem}
            >
              <span className={classes.navItemText}>{text}</span>
            </Link>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes.desktopContainer}>
      <header className={classes.parentNavChildNav}>
        {renderParentLinks()}
        <h2
          className={classes.childHeading}
          tabIndex={showChild === false ? 0 : -1}
          aria-label={showChild === false ? selectedItem.childHeading : ''}
          style={{
            display: showChild ? 'block' : 'none',
            opacity: showChild ? 1 : 0,
            transform: showChild ? 'translateX(-50%)' : 'translateX(-70%)'
          }}
        >
          {selectedItem.childHeading}
        </h2>
        <div
          className={classes.backButton}
          style={{
            opacity: showChild ? 1 : 0,
            transform: showChild ? 'translateX(0%)' : 'translateX(-20%)',
            pointerEvents: showChild ? 'all' : 'none'
          }}
          aria-hidden={!showChild}
        >
          <BackButton hideText />
        </div>
      </header>
    </div>
  );
};

export default ParentNavChildNav;
