import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from './store';
import * as api from '../api';
import uiSlice from './uiSlice';

export interface MemberSavingItemInterface {
  foodSavings: number;
  totalSavings: any;
}

export interface MemberSaveInterface {
  memberSavingsDataList: any;
  totalSavings: any;
}

const initialState: MemberSaveInterface = {
  memberSavingsDataList: {},
  totalSavings: null,
  
};

const memberSavingSlice = createSlice({
  name: 'memberSavingSlice',
  initialState,
  reducers: {
    setMemberSavingData(state, action: PayloadAction<MemberSaveInterface>) {
      state.memberSavingsDataList = action.payload;
    },
  }
});

export const fetchMemberSavings = (): AppThunk => async (dispatch, getState) => {
  const apiKey = 'fetchMemberSavings';
  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const memberSavingsData = await api.fetchMemberSavings(token as string); 

    dispatch(memberSavingSlice.actions.setMemberSavingData(memberSavingsData));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default memberSavingSlice;
