import React from 'react';
import { ReactComponent as CardIcon } from './icons/card-icon.svg';
import { ReactComponent as HomeIcon } from './icons/home-icon.svg';
import { ReactComponent as OffersIcon } from './icons/offers-icon.svg';
import { ReactComponent as RewardsIcon } from './icons/rewards-icon.svg';
import { ReactComponent as Settings } from './icons/settings-icon.svg';
import { ReactComponent as FullWidthView } from './icons/full-width-view-icon.svg';
import { ReactComponent as GridView } from './icons/grid-view-icon.svg';
import { ReactComponent as StoreLocator } from './icons/store-locator-icon.svg';
import { ReactComponent as ChevronRight } from './icons/chevron-right-icon.svg';
import { ReactComponent as Facebook } from './icons/facebook-icon.svg';
import { ReactComponent as Twitter } from './icons/twitter-icon.svg';
import { ReactComponent as LinkedIn } from './icons/linked-in-icon.svg';
import { ReactComponent as Location } from './icons/location-icon.svg';
import { ReactComponent as Youtube } from './icons/youtube-icon.svg';
import { ReactComponent as FaqIcon } from './icons/faqIcon.svg';
import { ReactComponent as BackArrow } from './icons/back-arrow.svg';
import { RewardIconInActive, RewardIconActive } from '../../assets/images';

import classes from './Icon.module.css';

export interface IconProps {
  isSelected?: boolean;
  iconName:
    | 'memberCard'
    | 'home'
    | 'offers'
    | 'rewards'
    | 'settings'
    | 'fullWidthView'
    | 'storeLocator'
    | 'chevronRight'
    | 'gridView'
    | 'facebook'
    | 'twitter'
    | 'linkedIn'
    | 'location'
    | 'faqIcon'
    | 'youtube'
    | 'rewardsInactive'
    | 'backArrow'
    | 'unsubscribe';
}

const Icon: React.FC<IconProps> = ({ iconName, isSelected = false }) => {
  let icon = null;
  switch (iconName) {
    case 'memberCard':
      icon = <CardIcon />;
      break;
    case 'offers':
      icon = <OffersIcon />;
      break;
    case 'rewards':
      icon = <RewardsIcon />;
      break;
    case 'home':
      icon = <HomeIcon />;
      break;
    case 'settings':
      icon = <Settings />;
      break;
    case 'fullWidthView':
      icon = <FullWidthView />;
      break;
    case 'gridView':
      icon = <GridView />;
      break;
    case 'storeLocator':
      icon = <StoreLocator />;
      break;
    case 'chevronRight':
      icon = <ChevronRight />;
      break;
    case 'facebook':
      icon = <Facebook />;
      break;
    case 'twitter':
      icon = <Twitter />;
      break;
    case 'linkedIn':
      icon = <LinkedIn />;
      break;
    case 'location':
      icon = <Location />;
      break;
    case 'youtube':
      icon = <Youtube />;
      break;
    case 'faqIcon':
      icon = <FaqIcon />;
      break;
    case 'rewardsInactive':
      icon = (isSelected && <RewardIconActive />) || <RewardIconInActive />;
      break;
    case 'backArrow':
      icon = <BackArrow />;
      break;
    case 'unsubscribe':
      icon = <BackArrow />;
      break;
    default:
      // eslint-disable-next-line no-console
      console.warn('Incorrect icon name specified');
      return null;
  }

  return (
    <i aria-hidden className={classes.iconContainer}>
      {icon}
    </i>
  );
};

export default Icon;
