import { lazy } from 'react';
import store from './appState/store';
import { IconProps } from './components/Icon/Icon';

const HomePage = lazy(() => import('./screens/Home/Home'));
const OffersPage = lazy(() => import('./screens/Offers/Offers'));
const MembershipCard = lazy(() =>
  import('./screens/MembershipCard/MembershipCard')
);
const SettingsPage = lazy(() => import('./screens/Settings/Settings'));
const RewardsPage = lazy(() => import('./screens/Rewards/Rewards'));
const CommunityHubPage = lazy(() => import('./screens/CommunityHub/CommunityHub')); 

interface NavigationConfigItem extends IconProps {
  text: string;
  path: string;
  exact?: boolean;
  pathParams?: string;
  component: any;
  externalLink?: boolean;
  hideDesktopNav?: boolean;
  hide?: boolean;
}

const isStampsRewards = store.getState()?.uiSlice?.isStampsRewards || false;
export const navigation: Array<NavigationConfigItem> = [
  {
    text: 'Home',
    path: '/',
    exact: true,
    component: HomePage,
    iconName: 'home'
  },
  {
    text: 'Value',
    path: '/offers/',
    pathParams: ':id?',
    exact: true,
    component: OffersPage,
    iconName: 'offers'
  },
  {
    text: 'Rewards',
    path: '/rewards/',
    pathParams: ':id?',
    component: RewardsPage,
    iconName: 'rewardsInactive',
    hide: true
  },
  {
    text: isStampsRewards ? 'Rewards' : 'Your Co-op',
    path: isStampsRewards ? '/rewards/' : '/getinvolved/',
    pathParams: ':id?',
    component: RewardsPage,
    iconName: 'rewardsInactive'
  },
  {
    text: 'Member Card',
    path: '/membership-card/',
    component: MembershipCard,
    iconName: 'memberCard',
    hideDesktopNav: true
  },
  {
    text: 'Store Locator',
    path: 'https://www.midcounties.coop/stores/',
    externalLink: true,
    component: null,
    iconName: 'storeLocator',
    hideDesktopNav: true
  },
  {
    text: 'Settings',
    path: '/settings/',
    pathParams: ':childPage?',
    component: SettingsPage,
    iconName: 'settings'
  },
  {
    text: 'Community',
    path: '/community-hub/',
    pathParams: ':id?',
    exact: true,
    component: CommunityHubPage,
    iconName: 'offers'
  }
];
