import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import CryptoJS from 'crypto-js';

import { AppThunk } from './store';
import uiSlice from './uiSlice';
import * as api from '../api';
import jsondata from '../mock-data/unsubscribe.json';
export interface OptionItemInterface {
  optionId: string;
  label: string;
  value: string;
  selected: boolean;
}

export interface SummaryInterface {
  summaryId: string;
  label: string;
}

export interface SectionInterface {
  sectionId: string;
  options: Array<OptionItemInterface>;
  summary: SummaryInterface;
}

export interface SettingsInterface {
  id: string | null;
  customerId: string | null;
  preferenceSet?: boolean | null;
  sections: Array<SectionInterface>;
  apiPostError?: any;
}

const initialState: SettingsInterface = {
  customerId: '',
  id: '',
  preferenceSet: null,
  sections: [],
  apiPostError: null
};

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setUserSettingsData(state, action: PayloadAction<SettingsInterface>) {
      const { id, customerId, sections } = action.payload;
      state.id = id;
      state.customerId = customerId;
      state.sections = sections;
    },
    setApiPostError(state, action) {
      console.log('action:', action);
    },
    clearApiPostError(state) {
      state.apiPostError = null;
    }
  }
});

export const fetchUserPreferences = (): AppThunk => async (
  dispatch,
  getState
) => {
  const apiKey = 'fetchUserPreferences';

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token } = getState().authSlice;
    const settingsData = await api.fetchUserSettings(token as string); 
    
    dispatch(settingsSlice.actions.setUserSettingsData(settingsData));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export const fetchUnsubscribePreferences = (): AppThunk => async (
  dispatch,
  getState
) => {
  const apiKey = 'fetchUnsubscribePreferences';

  dispatch(uiSlice.actions.clearApiError(apiKey));
  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const customerId: any = getState().authSlice; 
    const memberId = CryptoJS.SHA512('exponea-salt' + customerId.memberId).toString();

    const unsubscribeData = await api.fetchUnsubscibeSettings(customerId.memberId, memberId); 
    
    dispatch(settingsSlice.actions.setUserSettingsData(unsubscribeData));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export const updateUserPreferences = (
  settingsData: Array<SectionInterface>
): AppThunk => async (dispatch, getState) => {
  const apiKey = 'updateUserPreferences';

  // Note: errors for this are handled within this slice unlike GET errors
  dispatch(settingsSlice.actions.clearApiPostError());

  dispatch(uiSlice.actions.addApiLoading(apiKey));

  try {
    const { token = '', memberId } = getState().authSlice;
    const { id } = getState().userSettingsSlice;

    const requestData: SettingsInterface = {
      id,
      customerId: memberId,
      sections: settingsData
    }; 

    await api.updateUserPreferences(token as string, requestData);

    dispatch(
      settingsSlice.actions.setUserSettingsData({
        ...requestData,
        preferenceSet: true
      })
    );
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(
      uiSlice.actions.setApiError({ status, message, apiKey, type: 'post' })
    );
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};


export default settingsSlice;
