import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as api from '../api';
import { AppThunk } from './store';
import uiSlice from './uiSlice';

export interface MemberGUIDInterface {
  memberGUID: String;
}

const initialState: MemberGUIDInterface = {
  memberGUID: "",
};

const memberGUIDSlice = createSlice({
  name: 'memberGUIDSlice',
  initialState,
  reducers: {
    setMemberGUIDData(state, action: PayloadAction<MemberGUIDInterface>) {
      state.memberGUID = action.payload.memberGUID;
    }
  }
});

export const fetchMemberGUID = (): AppThunk => async (
    dispatch, getState
    ) => {
    const apiKey = 'fetchMemberGUID';

    dispatch(uiSlice.actions.clearApiError(apiKey));
    dispatch(uiSlice.actions.addApiLoading(apiKey));
  try {
    const { token } = getState().authSlice;
    const memberGuid = await api.fetchMemberGUID(`${token}` as string);
    dispatch(memberGUIDSlice.actions.setMemberGUIDData({ memberGUID: memberGuid.memberGuid }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  } catch ({ status, message }) {
    dispatch(uiSlice.actions.setApiError({ status, message, apiKey }));
    dispatch(uiSlice.actions.removeApiLoading(apiKey));
  }
};

export default memberGUIDSlice;
