import React, { useLayoutEffect, useRef } from 'react';
import anime, { AnimeTimelineInstance } from 'animejs';
import { getCookie } from '../../utils';
import classes from './Loading.module.css';

const isNative = getCookie('isNative') === 'true';

const Loading = ({ delayStart = 350 }) => {
  const timelineRef = useRef<AnimeTimelineInstance>();
  const leftCircleRef = useRef(null);
  const rightCircleRef = useRef(null);

  useLayoutEffect(() => {
    timelineRef.current = anime.timeline({
      easing: 'easeInOutSine',
      loop: true
    });

    const sectionDuration = 400;

    timelineRef.current
      .add(
        {
          // reveal
          targets: leftCircleRef.current,
          strokeDashoffset: 0,
          duration: sectionDuration
        },
        `+=${delayStart}`
      )
      .add({
        // reset ready to hide
        targets: leftCircleRef.current,
        strokeDashoffset: 1580,
        strokeDasharray: 800,
        duration: 1
      })
      .add(
        // reveal
        {
          targets: rightCircleRef.current,
          strokeDashoffset: 0,
          duration: sectionDuration
        },
        '-=80'
      )
      .add(
        // hide
        {
          targets: leftCircleRef.current,
          strokeDashoffset: 800,
          duration: sectionDuration
        },
        '+=50'
      )
      .add({
        // reset ready to revel
        targets: leftCircleRef.current,
        strokeDashoffset: -840,
        strokeDasharray: 1000,
        duration: 1
      })
      .add({
        // reset ready to hide
        targets: rightCircleRef.current,
        strokeDashoffset: -1580,
        strokeDasharray: 790,
        duration: 1
      })
      .add(
        // hide
        {
          targets: rightCircleRef.current,
          strokeDashoffset: -840,
          duration: sectionDuration
        }
      )
      .add({
        // reset ready to revel
        targets: rightCircleRef.current,
        strokeDashoffset: -840,
        strokeDasharray: 1000,
        duration: 1
      });
  }, [delayStart]);

  if (isNative) return null;

  return (
    <div className={classes.loadingContainer} data-testid="loading-icon">
      <svg className={classes.icon} viewBox="0 0 1920 1080">
        <g>
          <path
            ref={leftCircleRef}
            className={classes.leftCircle}
            d="M960,600.84c-27.84,38.05-73.1,62.8-124.21,62.8c-84.63,0-153.24-67.89-153.24-151.64
          s68.61-151.64,153.24-151.64c70.09,0,129.19,46.56,147.43,110.13"
          />
          <path
            ref={rightCircleRef}
            className={classes.rightCircle}
            d="M960,423.15c27.84-38.05,73.1-62.8,124.2-62.8c84.63,0,153.24,67.89,153.24,151.64
  s-68.61,151.64-153.24,151.64c-74.53,0-136.63-52.64-150.4-122.4"
          />
        </g>
      </svg>
    </div>
  );
};

export default Loading;
