// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0i4JsNx6PQ95IK7U08JDXA\\=\\= {\n  font-family: 'Museo Sans W01_500';\n  font-size: 12px;\n  line-height: 42px;\n  color: var(--emphasis-grey);\n  border: 0;\n  background: 0;\n  cursor: pointer;\n  outline: none;\n}\n\n.TxVirtsflEXXtzi6vcq4AQ\\=\\= {\n  display: inline-block;\n  vertical-align: middle;\n}\n\n._8-wFXcu\\+1ZJ0EH270MFRxA\\=\\= {\n  opacity: 0;\n}\n\n.bj7BKLnCOdJWfPZkJDJgVg\\=\\= {\n  fill: none;\n  display: inline-block;\n  vertical-align: middle;\n  padding: 10px;\n}\n\n.bj7BKLnCOdJWfPZkJDJgVg\\=\\= svg {\n  fill: none;\n}\n", "",{"version":3,"sources":["webpack://src/components/BackButton/BackButton.module.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,eAAe;EACf,iBAAiB;EACjB,2BAA2B;EAC3B,SAAS;EACT,aAAa;EACb,eAAe;EACf,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,qBAAqB;EACrB,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".backButton {\n  font-family: 'Museo Sans W01_500';\n  font-size: 12px;\n  line-height: 42px;\n  color: var(--emphasis-grey);\n  border: 0;\n  background: 0;\n  cursor: pointer;\n  outline: none;\n}\n\n.text {\n  display: inline-block;\n  vertical-align: middle;\n}\n\n.textHidden {\n  opacity: 0;\n}\n\n.icon {\n  fill: none;\n  display: inline-block;\n  vertical-align: middle;\n  padding: 10px;\n}\n\n.icon svg {\n  fill: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": "_0i4JsNx6PQ95IK7U08JDXA==",
	"text": "TxVirtsflEXXtzi6vcq4AQ==",
	"textHidden": "_8-wFXcu+1ZJ0EH270MFRxA==",
	"icon": "bj7BKLnCOdJWfPZkJDJgVg=="
};
export default ___CSS_LOADER_EXPORT___;
