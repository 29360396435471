// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SgnrNhWyQixrz1D7xybMNg\\=\\= {\n  position: fixed;\n  top: 0;\n  left: 0;\n  overflow: visible;\n  width: 100vw;\n  height: 100vh;\n  z-index: 4;\n  background: rgba(255, 255, 255, 0.7);\n}\n\n.MlRCaEPDUehcB2dq7fP5NA\\=\\= {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n.Qu0QhiQCFyHYBju4KRD8VQ\\=\\= {\n  fill: none;\n  stroke: var(--astral-blue);\n  stroke-width: 60;\n  stroke-linecap: round;\n}\n\n._2np6PVvqtyfeZHd\\+deRNpg\\=\\= {\n  stroke-dasharray: 920;\n  stroke-dashoffset: 1000;\n}\n\n._4NGXPK9YeRYXyzHKeBVR3g\\=\\= {\n  stroke-dasharray: 1000;\n  stroke-dashoffset: -840;\n}\n", "",{"version":3,"sources":["webpack://src/components/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,iBAAiB;EACjB,YAAY;EACZ,aAAa;EACb,UAAU;EACV,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;AAC7B;;AAEA;EACE,UAAU;EACV,0BAA0B;EAC1B,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EAEE,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EAEE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".loadingContainer {\n  position: fixed;\n  top: 0;\n  left: 0;\n  overflow: visible;\n  width: 100vw;\n  height: 100vh;\n  z-index: 4;\n  background: rgba(255, 255, 255, 0.7);\n}\n\n.icon {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n}\n\n.circle {\n  fill: none;\n  stroke: var(--astral-blue);\n  stroke-width: 60;\n  stroke-linecap: round;\n}\n\n.leftCircle {\n  composes: circle;\n  stroke-dasharray: 920;\n  stroke-dashoffset: 1000;\n}\n\n.rightCircle {\n  composes: circle;\n  stroke-dasharray: 1000;\n  stroke-dashoffset: -840;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingContainer": "SgnrNhWyQixrz1D7xybMNg==",
	"icon": "MlRCaEPDUehcB2dq7fP5NA==",
	"circle": "Qu0QhiQCFyHYBju4KRD8VQ==",
	"leftCircle": "_2np6PVvqtyfeZHd+deRNpg== Qu0QhiQCFyHYBju4KRD8VQ==",
	"rightCircle": "_4NGXPK9YeRYXyzHKeBVR3g== Qu0QhiQCFyHYBju4KRD8VQ=="
};
export default ___CSS_LOADER_EXPORT___;
